import authHeader from "./auth-header";

import axios from "axios";

import { environment } from "../env/config.env";

const API_URL = environment.api;

const addPaiement = ( paiement ) => {
  return axios.post(API_URL + "/paiementAchat/" , paiement ,{ headers: authHeader() });
};

const getPaiements = () => {
  return axios.get(API_URL + "/paiementAchat/" , { headers: authHeader() });
};
const getActivePaiements = (id) => {
  return axios.get(API_URL + "/paiementAchat/active/" + id , { headers: authHeader() });
};

const updatePaiement = (id , paiement ) => {
  return axios.put(API_URL + "/paiementAchat/" + id , paiement, { headers: authHeader() });
};

const deletePaiement = (id) => {
  return axios.delete(API_URL + "/paiementAchat/" + id , { headers: authHeader() });
};

const exportsMethodes = {
  addPaiement,
  getPaiements,
  updatePaiement,
  deletePaiement,
  getActivePaiements
};
export default exportsMethodes;