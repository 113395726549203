const currency = [
  { id: 1, code: "TND" , base : "Dinars" , sub : "Millimes" },
  { id: 2, code: "USD" , base : "Dollars" , sub : "Cent" },
  { id: 3, code: "EUR" , base : "Euro" , sub : "Centime" },
  { id: 4, code: "GBP" , base : "Livre sterling" , sub : "" },
  { id: 5, code: "CHF" , base : "Franc suisse" , sub : "" },
  { id: 6, code: "DZD" , base : "Dinar algérien" , sub : "" },
  { id: 7, code: "QAR" , base : "Riyal du Qatar" , sub : "" },
  { id: 8, code: "TRY" , base : "Livre turque" , sub : "" },
  { id: 9, code: "AED" , base : "Dirham émirati" , sub : "" },
];

export default currency;
