import {
    DeleteOutlined,
    EditOutlined,
    ExclamationCircleOutlined
  } from "@ant-design/icons";
  import {
    Button,
    Card,
    Col,
    Form,
    Input,
    message,
    Modal,
    Row,
    Spin,
    Switch,
    Tag,
    Tooltip
  } from "antd";
  import TextArea from "antd/lib/input/TextArea";
  import React, { useEffect, useState } from "react";
  import Can from "../../../../security/Can";
  import GroupeClientService from "../../../../services/groupeClient.service";
  import TableWithFiltres from "../../../uiHelpers/TableFilters";
  
  const GroupeClient = () => {
    const [groupeClientForm] = Form.useForm();
    const [categorieModal, setGroupeClientModal] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [updateId, setUpdateId] = useState();
    const [dataTable, setDataTable] = useState([]);
    const [switchValue, setSwitchValue] = useState("unchecked");
  
  
    useEffect(() => {
      loadGroupeClients();
      return () => {
        clearStates();
      };
    }, []);
  
    const clearStates = () => {
      setDataTable([]);
      setEditMode(false);
      setUpdateId(null);
    };
    const loadGroupeClients = async () => {
      setLoading(true);
      const response = await GroupeClientService.getGroupeClients();
      if (response.status === 200 || 204) initTableData(response.data);
      else message.error("Une erreur est survenu ! ");
      setLoading(false);
    };
    const initTableData = (data) => {
      setDataTable(data);
    };
  
    const showGroupeClientModal = () => {
      setGroupeClientModal(true);
    };
  
    const handleOk = () => {
      setGroupeClientModal(false);
    };
  
    const updateMode = (record) => {
      setGroupeClientModal(true);
      const checkedSwitch = record.etat ? "checked" : "unchecked";
      setSwitchValue(checkedSwitch);
      groupeClientForm.setFieldsValue(record);
      setUpdateId(record.id);
      setEditMode(true);
    };
  
    const handleCancel = () => {
      setGroupeClientModal(false);
      clearData();
    };
  
    const addGroupeClient = async (values) => {
      setLoading(true);
      var data = values
      data.etat = data.etat ? data.etat : false; 
      const response = editMode
        ? await GroupeClientService.updateGroupeClient(updateId, data)
        : await GroupeClientService.addGroupeClient(data);
      if (response.status === 200 || 204) {
        if (editMode) message.info("Mise à jour avec success");
        else message.info("GroupeClient ajouter avec success");
      } else message.error("Une erreur est survenu ! ");
  
      closingModal();
    };
  
    const closingModal = () => {
      loadGroupeClients();
      setGroupeClientModal(false);
      setLoading(false);
      clearData();
    };
  
    const clearData = () => {
      groupeClientForm.resetFields();
      setEditMode(false);
      setUpdateId(null);
    };
  
    const GroupeClientColumns = [
      {
        title: "Nom du groupe",
        dataIndex: "nom",
        sorter: (a, b) => a.nom.localeCompare(b.nom),
        width: "17%",
      },
      {
        title: "Description",
        dataIndex: "description",
        width: "55%",
        sorter: (a, b) => a.description.localeCompare(b.description),
      },
      {
        title: "Etat",
        dataIndex: "etat",
        sorter: (a, b) => a.valeur - b.valeur,
        render: (text, record) => (
          <Tag color={record.etat ? "cyan" : "red" }>{record.etat ? "Activé" : "Désactivé"}</Tag>
        ),
        width: "10%",
      },
      {
        title: "Action",
        key: "action",
        width: "10%",
        render: (text, record) => (
          <div>
            <Tooltip title="Mettre à jour">
              <Button
                className="mx-1"
                type="dashed"
                shape="circle"
                disabled={!Can("3_3")}
                onClick={() => updateMode(record)}
                icon={<EditOutlined />}
              />
            </Tooltip>
          </div>
        ),
      },
    ];
  
    const confirmDelete = (record) => {
      Modal.confirm({
        title: "Attention",
        icon: <ExclamationCircleOutlined />,
        content: "Êtes-vous sûr de vouloir supprimer cette GroupeClient ?",
        okText: "Oui",
        onOk: () => deleteItem(record),
        cancelText: "Non",
      });
    };
    const deleteItem = async (item) => {
      const response = await GroupeClientService.deleteGroupeClient(item.id);
      if (response.status === 204 || 200) {
        message.info("Suppression avec success");
        loadGroupeClients();
      } else message.error("Une erreur est survenu ! ");
    };
  
    return (
      <Spin spinning={isLoading} size="large">
        <Card
          
          title={"GroupeClient"}
          extra={
              <Button
                className="mx-2"
                shape="round"
                type="primary"
                disabled={!Can("3_2")}
                onClick={() => showGroupeClientModal()}
              >
                Ajouter un Groupe de client
              </Button>
          }
        >
          <TableWithFiltres data={dataTable} columns={GroupeClientColumns}></TableWithFiltres>
          <Form layout="vertical" form={groupeClientForm} onFinish={addGroupeClient}>
            <Modal
              visible={categorieModal}
              onOk={groupeClientForm.submit}
              onCancel={handleCancel}
              getContainer={false}
              okText="Enregistrer"
              cancelText="Annuler"
              centered
              width={600}
              title={
                <>
                  <div className="d-flex justify-content-between ">
                    <div className="my-0">
                      {editMode
                        ? "Modifier le groupe des client"
                        : "Ajouter un groupe de client"}
                    </div>
                    <div>
                      <Form.Item
                        className="my-0 mx-5"
                        name="etat"
                        valuePropName={switchValue}
                      >
                        <Switch
                          checkedChildren="Activer"
                          unCheckedChildren="Désactiver"
                          className="px-4"
                        />
                      </Form.Item>
                    </div>
                  </div>
                </>
              }
            >
              <Row>
                <Col span={24} className="px-2">
                  <Form.Item
                    label="Nom du groupe Client "
                    name="nom"
                    rules={[
                      {
                        required: true,
                        message:
                          "Champ obligatoire avec maximum de caractère 100 !",
                        max: 99,
                      },
                    ]}
                  >
                    <Input size="large" type="text" maxLength={100} />
                  </Form.Item>
                </Col>
  
                <Col span={24} className="px-2">
                  <Form.Item
                    label="Description"
                    name="description"
                    rules={[
                      {
                        message:
                          "Champ obligatoire avec maximum de caractère 255 !",
                        max: 254,
                      },
                    ]}
                  >
                    <TextArea rows={4} type="text" maxLength={255} />
                  </Form.Item>
                </Col>
              </Row>
            </Modal>
          </Form>
        </Card>
      </Spin>
    );
  };
  export default GroupeClient;
  