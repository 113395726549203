import authHeader from "./auth-header";

import axios from "axios";

import { environment } from "../env/config.env";

const API_URL = environment.api;

const addFactureAchat = async ( facture ) => {
  return await axios.post(API_URL + "/facture/" , facture ,{ headers: authHeader() });
};

const getFactureAchats = async ( callback ) => {
  callback( await axios.get(API_URL + "/facture/" , { headers: authHeader() }))
};


const getFactureAchat = async ( id, callback ) => {
  callback( await axios.get(API_URL + "/facture/" + id , { headers: authHeader() }))
};
const updateFactureAchat = (id , facture ) => {
  return axios.put(API_URL + "/facture/" + id , facture, { headers: authHeader() });
};

const deleteFactureAchat = (id) => {
  return axios.delete(API_URL + "/facture/" + id , { headers: authHeader() });
};
const changeEtat = (id ,etat) => {
  return axios.post(API_URL + "/facture/state/" + id , {etat : etat} , { headers: authHeader() });
};

const getActiveReception = async (id) => {
  return await axios.get(API_URL + "/facture/active/" + id ,{ headers: authHeader() });
}

const getLast = async (callback) => {
  callback( await axios.get(API_URL + "/facture/last", { headers: authHeader() }))
}

const loadFactureMontants = async () =>{
  return await axios.get(API_URL + "/facture/montants/"  ,{ headers: authHeader() });
}

const loadByStatus = async () => {
  return await axios.get(API_URL + "/facture/byStatus/"  ,{ headers: authHeader() });

}


const exportsMethodes = {
  addFactureAchat,
  getFactureAchats,
  getFactureAchat,
  updateFactureAchat,
  deleteFactureAchat,
  changeEtat,
  getActiveReception,
  getLast,
  loadFactureMontants,
  loadByStatus
};
export default exportsMethodes;