import authHeader from "./auth-header";

import axios from "axios";

import { environment } from "../env/config.env";

const API_URL = environment.api;

const addTicketCaisse = async ( ticket_caisse ) => {
  return await axios.post(API_URL + "/ticket_caisse/" , ticket_caisse ,{ headers: authHeader() });
};

const getAllTicketCaisse = async ( callback ) => {
  callback( await axios.get(API_URL + "/ticket_caisse/" , { headers: authHeader() }))
};


const getTicketCaisse = async ( id, callback ) => {
  callback( await axios.get(API_URL + "/ticket_caisse/" + id , { headers: authHeader() }))
};

const getTicketCaissesNames = async (callback) => {
  callback( await axios.get(API_URL + "/ticket_caisse/names" , { headers: authHeader() }))
}

const updateTicketCaisse = (id , ticket_caisse ) => {
  return axios.put(API_URL + "/ticket_caisse/" + id , ticket_caisse, { headers: authHeader() });
};

const deleteTicketCaisse = (id) => {
  return axios.delete(API_URL + "/ticket_caisse/" + id , { headers: authHeader() });
};
const changeTicketCaisseStatus = (id,etat) => {
  return axios.post(API_URL + "/ticket_caisse/change/" + id  ,{etat}, { headers: authHeader() });
};

const getLast = async (callback) => {
  callback( await axios.get(API_URL + "/ticket_caisse/last", { headers: authHeader() }))
}

const duplicate = async ( id) => {
  return axios.get(API_URL + "/ticket_caisse/duplicate/" + id , {headers: authHeader()})
}
const exportsMethodes = {
  addTicketCaisse,
  getAllTicketCaisse,
  getTicketCaisse,
  updateTicketCaisse,
  deleteTicketCaisse,
  changeTicketCaisseStatus,
  getTicketCaissesNames,
  getLast,
  duplicate
};
export default exportsMethodes;