import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  MinusCircleOutlined,
  ControlOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Row,
  Select,
  Spin,
  Tabs,
  Tooltip,
} from "antd";
import React, { useEffect, useState } from "react";
import country from "../../../helpers/country";
import Can from "../../../security/Can";
import FournisseurService from "../../../services/fournisseur.service";
import AppTable from "../../uiHelpers/AppTabe";
import NotAuthorized from "../../uiHelpers/NotAuthorized";

const Fournisseur = () => {
  const [fournisseurForm] = Form.useForm();
  const [fournisseurModal, setFournisseurModal] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [updateId, setUpdateId] = useState();
  const [switchValue, setSwitchValue] = useState("unchecked");
  const [isEntrprise, setFournisseurType] = useState(false);

  const [simpleDataTable, setSimpleDataTable] = useState([]);
  const [advancedDataTable, setAdvancedDataTable] = useState([]);
  const [simpleTable, setSimpleTable] = useState(true);

  useEffect(() => {
    loadFournisseurs();
    return () => {
      clearStates();
    };
  }, []);

  const clearStates = () => {
    setSimpleDataTable([]);
    setEditMode(false);
    setUpdateId(null);
  };
  const loadFournisseurs = async () => {
    setLoading(true);
    const response = await FournisseurService.getFournisseurs();
    if (response.status === 200 || 204) initTableData(response.data);
    else message.error("Une erreur est survenu ! ");
    setLoading(false);
  };
  const initTableData = (data) => {
    setSimpleDataTable(data);
  };

  const showFournisseurModal = () => {
    setFournisseurModal(true);
  };

  const handleOk = () => {
    setFournisseurModal(false);
  };

  const updateMode = (record) => {
    setFournisseurModal(true);
    const checkedSwitch = record.type ? "checked" : "unchecked";
    setSwitchValue(checkedSwitch);
    fournisseurForm.setFieldsValue(record);
    setUpdateId(record.id);
    setEditMode(true);
  };

  const handleCancel = () => {
    setFournisseurModal(false);
    clearData();
  };

  const addFournisseur = async (values) => {
    var data = values;
    data.type = data.type ? data.type : false;
    const response = editMode
      ? await FournisseurService.updateFournisseur(updateId, values)
      : await FournisseurService.addFournisseur(values);
    if (response.status === 200 || 204) {
      if (editMode) message.info("Mise à jour avec success");
      else message.info("Fournisseur ajouter avec success");
    } else message.error("Une erreur est survenu ! ");

    closingModal();
  };

  const closingModal = () => {
    loadFournisseurs();
    setFournisseurModal(false);
    setLoading(false);
    setSwitchValue("unchecked");
    clearData();
  };

  const clearData = () => {
    fournisseurForm.resetFields();
    fournisseurForm.setFieldsValue({ type: false });
    setEditMode(false);
    setUpdateId(null);
  };

  const SimpleFournisseurColumns = [
    {
      title: "Raison Social",
      dataIndex: "raison_sociale",
      sorter: (a, b) => a.raison_sociale.localeCompare(b.raison_sociale),
    },
    {
      title: "Nom",
      dataIndex: "nom",
      sorter: (a, b) => a.nom.localeCompare(b.nom),
    },
    {
      title: "E-mail",
      dataIndex: "email",
      sorter: (a, b) => a?.email?.localeCompare(b?.email),
    },
    {
      title: "Téléphone ",
      dataIndex: "telephone_pro",
      sorter: (a, b) => a?.telephone_pro - b?.telephone_pro,
    },
    {
      title: (
        <>
          Actions
          <Tooltip title="Affichage Avancé">
            <Button
              onClick={() => fournisseurFactures()}
              className="mx-3"
              type="dashed"
              icon={<ControlOutlined />}
            />
          </Tooltip>
        </>
      ),
      key: "action",
      width: "15%",
      render: (text, record) => (
        <div>
          <Tooltip title="Mettre à jour">
            <Button
              className="mx-1"
              type="dashed"
              shape="circle"
              disabled={!Can("9_3")}
              onClick={() => updateMode(record)}
              icon={<EditOutlined />}
            />
          </Tooltip>
          <Tooltip title="Supprimer">
            <Button
              className="mx-1"
              type="dashed"
              shape="circle"
              disabled
              onClick={() => confirmDelete(record)}
              icon={<DeleteOutlined />}
            />
          </Tooltip>
        </div>
      ),
    },
  ];
  const AdvancedFournisseurColumns = [
    {
      title: "Raison Social",
      dataIndex: "raison_sociale",
      sorter: (a, b) => a.raison_sociale.localeCompare(b.raison_sociale),
    },
    {
      title: "Nom",
      dataIndex: "nom",
      sorter: (a, b) => a.nom.localeCompare(b.nom),
    },
    {
      title: "E-mail",
      dataIndex: "email",
      sorter: (a, b) => a?.email?.localeCompare(b?.email),
    },
    {
      title: "Téléphone ",
      dataIndex: "telephone_pro",
      sorter: (a, b) => a?.telephone_pro - b?.telephone_pro,
    },
    {
      title: "Rest à payé",
      dataIndex: "notPayed",
      sorter: (a, b) => a?.notPayed?.localeCompare(b?.notPayed),
    },
    {
      title: "Paiement en cours",
      dataIndex: "enGoing",
      sorter: (a, b) => a?.enGoing?.localeCompare(b?.enGoing),
    },
    {
      title: "Payé",
      dataIndex: "payed",
      sorter: (a, b) => a?.payed?.localeCompare(b?.notPayed),
    },
    {
      title: (
        <>
          Actions
          <Tooltip title="Affichage simple">
            <Button
              onClick={() => setSimpleTable(true)}
              className="mx-3"
              type="dashed"
              icon={<ControlOutlined />}
            />
          </Tooltip>
        </>
      ),
      key: "action",
      width: "15%",
      render: (text, record) => (
        <div>
          <Tooltip title="Mettre à jour">
            <Button
              className="mx-1"
              type="dashed"
              shape="circle"
              disabled
              onClick={() => updateMode(record)}
              icon={<EditOutlined />}
            />
          </Tooltip>
          <Tooltip title="Supprimer">
            <Button
              className="mx-1"
              type="dashed"
              shape="circle"
              disabled
              onClick={() => confirmDelete(record)}
              icon={<DeleteOutlined />}
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  const fournisseurFactures = async () => {
    if (!advancedDataTable.length) {
      const list = simpleDataTable;
      for (const element of list) {
        const response = await FournisseurService.findFournisseurStats( element.id);
        const data = response.data;
        for (const stat of data) {
            element.notPayed = stat.paiement_status === 0 ? Number(stat.total).toFixed(3) : "0.000";
            element.payed = stat.paiement_status === 1 ? Number(stat.total).toFixed(3) : "0.000";
            element.enGoing =  stat.paiement_status === 2 ? Number(stat.total).toFixed(3) : "0.000";
        }
      }
      setAdvancedDataTable(list);
    }
    setSimpleTable(false);
  };

  const confirmDelete = (record) => {
    Modal.confirm({
      title: "Attention",
      icon: <ExclamationCircleOutlined />,
      content: "Êtes-vous sûr de vouloir supprimer cette Fournisseur ?",
      okText: "Oui",
      onOk: () => deleteItem(record),
      cancelText: "Non",
    });
  };
  const deleteItem = async (item) => {
    const response = await FournisseurService.deleteFournisseur(item.id);
    if (response.status === 204 || 200) {
      message.info("Suppression avec success");
      loadFournisseurs();
    } else message.error("Une erreur est survenu ! ");
  };
  const switchChange = () => {
    setFournisseurType(fournisseurForm.getFieldValue("type"));
  };

  return (
    <>
      {Can("8_1") ? (
        <Spin spinning={isLoading} size="large">
          <Card
            title={"Fournisseur"}
            extra={
              <Button
                className="mx-2"
                shape="round"
                type="primary"
                disabled={!Can("9_2")}
                onClick={() => showFournisseurModal()}
              >
                Ajouter un fournisseur
              </Button>
            }
          >
            {simpleTable && simpleTable ? (
              <AppTable
                data={simpleDataTable}
                columns={SimpleFournisseurColumns}
              />
            ) : (
              <AppTable
                data={advancedDataTable}
                columns={AdvancedFournisseurColumns}
              />
            )}
            <Form
              layout="vertical"
              form={fournisseurForm}
              onFinish={addFournisseur}
            >
              <Modal
                visible={fournisseurModal}
                onOk={fournisseurForm.submit}
                onCancel={handleCancel}
                getContainer={false}
                okText="Enregistrer"
                cancelText="Annuler"
                centered
                width={1100}
                title={
                  editMode
                    ? "Modifier le fournisseur"
                    : "Ajouter Un fournisseur"
                }
              >
                <Tabs defaultActiveKey="1">
                  <Tabs.TabPane tab="Informations Générales" key="1">
                    <Row>
                      <Col span={8} className="px-2">
                        <Form.Item
                          label="Raison Sociale"
                          name="raison_sociale"
                          rules={[
                            {
                              required: true,
                              message:
                                "Champ obligatoire avec maximum de caractère 100 !",
                              max: 99,
                            },
                          ]}
                        >
                          <Input size="large" type="text" maxLength={100} />
                        </Form.Item>
                      </Col>
                      <Col span={8} className="px-2">
                        <Form.Item
                          label="Matricule Fiscal"
                          name="matricule_fiscal"
                          rules={[
                            {
                              required: true,
                              message:
                                "Champ obligatoire avec maximum de caractère 100 !",
                              max: 99,
                            },
                          ]}
                        >
                          <Input size="large" type="text" maxLength={100} />
                        </Form.Item>
                      </Col>
                      <Col span={8} className="px-2">
                        <Form.Item
                          label="Registre Du commerce"
                          name="registre_commerce"
                          rules={[
                            {
                              required: true,
                              message:
                                "Champ obligatoire avec maximum de caractère 100 !",
                              max: 99,
                            },
                          ]}
                        >
                          <Input size="large" type="text" maxLength={100} />
                        </Form.Item>
                      </Col>

                      <Col span={12} className="px-2">
                        <Form.Item
                          label="Nom"
                          name="nom"
                          rules={[
                            {max :100 ,message:"Maximum de caractère 100 !",},
                            {required :true , message : "Champ obligatoire !"},
                            {pattern:/^([a-zA-Z]+\s)*[a-zA-Z]+$/ , message : "Format invalid !" },
                          ]}
                        >
                          <Input size="large" type="text" maxLength={100} />
                        </Form.Item>
                      </Col>
                      <Col span={12} className="px-2">
                        <Form.Item
                          label="Prénom"
                          name="prenom"
                          rules={[
                            {max :100 ,message:"Maximum de caractère 100 !",},
                            {required :true , message : "Champ obligatoire !"},
                            {pattern:/^([a-zA-Z]+\s)*[a-zA-Z]+$/ , message : "Format invalid !" },
                          ]}
                        >
                          <Input size="large" type="text" maxLength={100} />
                        </Form.Item>
                      </Col>
                      <Col span={12} className="px-2">
                        <Form.Item
                          label="E-mail"
                          name="email"
                          rules={[
                            {
                              type: 'email',
                              message: 'Format invalid!',
                            },
                            {
                              required: true,
                              message: 'champ obligatoire !',
                            },
                          ]}
                        >
                          <Input size="large" type="text" maxLength={100} />
                        </Form.Item>
                      </Col>
                      <Col span={12} className="px-2">
                        <Form.Item rules={[
                            {
                              pattern:/^[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/g,
                              message: 'Format invalid!',
                            },
                          ]}
                          label="Site Web" 
                          name="site">
                          <Input size="large" type="text" maxLength={100} />
                        </Form.Item>
                      </Col>

                      <Col span={3} className="px-2">
                        <Form.Item
                          label="Code Tél"
                          name="code_phone_pro"
                          rules={[
                            {
                              required: true,
                              message:
                                "Champ obligatoire avec maximum de caractère 100 !",
                            },
                          ]}
                        >
                          <Select size="large">
                            {country &&
                              country.map((c) => (
                                <Select.Option
                                  key={c.id}
                                  value={c.telCode}
                                >
                                  {c.telCode}  {c.name}
                                </Select.Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={5} className="px-2">
                        <Form.Item
                          label="Numéro Téléphone "
                          name="telephone_pro"
                          tooltip="Maximum 13 chiffres"
                          rules={[
                            {
                              required: true,
                              message:
                                "Champ obligatoire avec maximum de caractère 100 !",
                            },
                          ]}
                        >
                          <InputNumber
                            min={0}
                            max={99999999999999}
                            size="large"
                            style={{ width: "100%" }}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={3} className="px-2">
                        <Form.Item
                          label="Code Tél"
                          name="code_phone_per"
                        >
                          <Select size="large">
                            {country &&
                              country.map((c) => (
                                <Select.Option
                                  key={c.id}
                                  value={c.telCode}
                                >
                                  {c.telCode}  {c.name}
                                </Select.Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={5} className="px-2">
                        <Form.Item
                          label="Numéro Personnel"
                          name="telephone_personnel"
                          tooltip="Maximum 13 chiffres"
                        >
                          <InputNumber
                            max={99999999999999}
                            size="large"
                            style={{ width: "100%" }}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={3} className="px-2">
                        <Form.Item
                          label="Code Tél"
                          name="code_fax"
                        >
                          <Select size="large">
                            {country &&
                              country.map((c) => (
                                <Select.Option
                                  key={c.id}
                                  value={c.telCode}
                                >
                                  {c.telCode}  {c.name}
                                </Select.Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={5} className="px-2">
                        <Form.Item
                          label="Numéro Fax"
                          name="telephone_fax"
                          tooltip="Maximum 13 chiffres"
                        >
                          <InputNumber
                            min={0}
                            max={99999999999999}
                            size="large"
                            style={{ width: "100%" }}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Tabs.TabPane>
                  <Tabs.TabPane tab="Adresses du fournisseur " forceRender={true} key="2">
                    <Row>
                      <Col span={24}>
                        <b className="p-2">Adresses du fournisseur :</b>
                      </Col>

                      <Form.List
                        name="adresse_fournisseurs"
                        label="List des addresses"
                      >
                        {(fields, { add, remove }) => (
                          <>
                            {fields.map(({ key, name, ...restField }) => (
                              <>
                                <Col span={6} className="px-2 mt-2">
                                  <Form.Item
                                    {...restField}
                                    name={[name, "pays"]}
                                    label="Pays"
                                    rules={[
                                      {
                                        required: true,
                                        message:
                                          "Champ obligatoire avec maximum de caractère 100 !",
                                      },
                                    ]}
                                  >
                                    <Select size="large">
                                      {country.map((c) => (
                                        <Select.Option
                                          key={c.id}
                                          value={c.name}
                                        >
                                          {c.name}
                                        </Select.Option>
                                      ))}
                                    </Select>
                                  </Form.Item>
                                </Col>
                                <Col span={6} className="px-2 mt-2">
                                  <Form.Item
                                    {...restField}
                                    name={[name, "ville"]}
                                    label="Ville"
                                    rules={[
                                      {
                                        required: true,
                                        message:
                                          "Champ obligatoire avec maximum de caractère 100 !",
                                        max: 99,
                                      },
                                    ]}
                                  >
                                    <Input size="large" />
                                  </Form.Item>
                                </Col>
                                <Col span={11} className="px-2 mt-2">
                                  <Form.Item
                                    {...restField}
                                    name={[name, "adresse"]}
                                    label="Adresse"
                                    rules={[
                                      {
                                        required: true,
                                        message:
                                          "Champ obligatoire avec maximum de caractère 100 !",
                                        max: 99,
                                      },
                                    ]}
                                  >
                                    <Input size="large" />
                                  </Form.Item>
                                </Col>
                                <Col span={1} className="px-2 mt-2">
                                  <Form.Item label=" ">
                                    <MinusCircleOutlined
                                      size="large"
                                      onClick={() => remove(name)}
                                    />
                                  </Form.Item>
                                </Col>
                              </>
                            ))}
                            <Row>
                              <Col span={12} className="px-2 mt-3">
                                <Button type="dashed" onClick={() => add()}>
                                  Ajouter une adresse
                                </Button>
                              </Col>
                            </Row>
                          </>
                        )}
                      </Form.List>
                    </Row>
                  </Tabs.TabPane>
                </Tabs>
              </Modal>
            </Form>
          </Card>
        </Spin>
      ) : (
        <NotAuthorized></NotAuthorized>
      )}
    </>
  );
};
export default Fournisseur;
