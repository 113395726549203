import { Col, Row, Spin } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import {
    Bar,
    BarChart,
    CartesianGrid, Cell, Legend, Line, LineChart, Pie, PieChart, Tooltip, XAxis,
    YAxis
} from "recharts";
import FactureAchatService from "../../../services/FactureAchat.service";
import FactureVenteService from "../../../services/factureVente.service";
import bonCommandeVente from "../../../services/bonCommandeVente.service";



const colors = [
    "#8884d8","#84D8B2","#D884AA",'#D4D884', '#84AAD8', '#B284D8'
]
const months = [
    "01/2022",
    "02/2022",
    "03/2022",
    "04/2022",
    "05/2022",
    "06/2022",
    "07/2022",
    "08/2022",
    "09/2022",
    "10/2022",
    "11/2022",
    "12/2022",
]

export default function LeadChart() {
    const [isLoading, setLoading] = useState(false);
    const [firstChart, setfirstChart] = useState([]);
    const [facturesAchat, setfacturesAchat] = useState([]);
    const [facturesVente, setfacturesVente] = useState([]);
    const [chartData, setChartData] = useState([]);
    const [statusAchat , setStatusAchat] = useState([]);
    const [statusVente , setStatusVente] = useState([]);
    const [commandesProjet , setCommandesProjet] = useState([]);

    useEffect(() => {
        async function loadData(){
            await getFactures();
        }
        loadData();

    }, []);

    const getFactures = async () => {
        const facturesA = await FactureAchatService.loadFactureMontants(); 
        const facturesV = await FactureVenteService.loadFactureMontants(); 
        const statusAchats = await FactureAchatService.loadByStatus(); 
        const statusVentes = await FactureVenteService.loadByStatus(); 
        const bonCommandeByStatus = await bonCommandeVente.getStatByProjet();

        const achats = facturesA.data;
        const ventes = facturesV.data;
        const statusA = statusAchats.data;
        const statusV = statusVentes.data;
        const commandes = bonCommandeByStatus.data;

        const data = [];
        for (const month of months) {
            var achatValue = 0;
            var venteValue = 0;

            for (const element of achats) {
                if(moment(element.date).format("MM/YYYY") + "" === month)
                    achatValue += Number(element.montant_total) 
            }
            for (const element of ventes) {
                if(moment(element.date).format("MM/YYYY") + "" === month)
                    venteValue += Number(element.montant_total)             
            }
            data.push({
                vente : (venteValue).toFixed(3),
                achat : (achatValue).toFixed(3),
                month : month,
            })
        }
        const achatStatus = [];
        for (const element of statusA) {
            if( element.name === -1  ){
                achatStatus.push({
                    name : "Annulé",
                    value: element.total,
                    nombre : element.value
                })
            }else if(element.name === 0 ){
                achatStatus.push({
                    name : "En Cours",
                    value: element.total,
                    nombre : element.value
                })
            }else{
                achatStatus.push({
                    name : "Validé",
                    value: element.total,
                    nombre : element.value
                })
            }
            
        }
        const venteStatus = [];
        for (const element of statusV) {
            if( element.name === -1  ){
                venteStatus.push({
                    name : "Annulé",
                    value: element.total,
                    nombre : element.value
                })
            }else if(element.name === 0 ){
                venteStatus.push({
                    name : "En Cours",
                    value: element.total,
                    nombre : element.value
                })
            }else{
                venteStatus.push({
                    name : "Validé",
                    value: element.total,
                    nombre : element.value
                })
            }
            
        }
        const commandesByStatus = [];
        var nbr = 0;
        var total = 0;
        for (const commande of commandes) {
            if(commande.projet === null){
                nbr += commande.nbr
                total +=  Number((commande.total).toFixed(3))
            }else{
                commandesByStatus.push({
                    titre: commande.projet?.titre,
                    nbr: commande.nbr,
                    total: Number((commande.total).toFixed(3)),
                })
            }
        }
        commandesByStatus.push({titre: "Sans Projet",nbr,total})

        setCommandesProjet(commandesByStatus);
        setStatusAchat(achatStatus);
        setStatusVente(venteStatus);
        setChartData(data);
        setfacturesAchat(facturesA.data);
        setfacturesVente(facturesV.data);

    }
    
    return (
        <Spin spinning={isLoading} size="large">
            <Row>
                <Col span={24}  >
                    <h5 className="p-3">Rapport de vente et d'achat : </h5>
                    <LineChart
                        width={1200}
                        height={400}
                        data={chartData}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                        }}
                        >
                        <CartesianGrid strokeDasharray="1 1" />
                        <XAxis dataKey="month" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Line type="monotone" dataKey="vente" stroke="#8884d8" activeDot={{ r: 8 }} />
                        <Line type="monotone" dataKey="achat" stroke="#82ca9d" />
                    </LineChart>

                </Col>
                <Col span={24} className="my-5" >
                    <h5 className=" p-3">Bons de commande par projet : </h5>
                    <BarChart
                        width={1100}
                        height={500}
                        data={commandesProjet}
                    >
                        <XAxis dataKey="titre" />
                        <YAxis />
                        <Tooltip />
                        <CartesianGrid strokeDasharray="1 1" />

                        <Bar dataKey="total" fill="#8884d8" width="30px"> 
                        {
                            commandesProjet.map((entry, index) => <Cell key={index} fill={colors[index]}/>)
                        }
                        </Bar>
                    </BarChart>

                </Col>
                <Col span={12} className="mt-3" >
                <h5 className="text-center" >Facture d'achat par statut: </h5>

                    <PieChart width={500} height={400} className="mt-0 pt-0">
                        <Pie
                            className="pt-0 mt-0"
                            dataKey="nombre"
                            startAngle={0}
                            endAngle={380}
                            data={statusAchat}
                            outerRadius={100}
                            fill="#8884d8"
                            label
                        >
                        {
                            statusAchat.map((entry, index) => <Cell key={index} fill={colors[index]}/>)
                        }
                        </Pie>
                        <Legend />
                        <Tooltip content={<CustomTooltip />} />
                        

                    </PieChart>
                </Col>

                <Col span={12} className="mt-3"  >
                <h5 className="text-center" >Facture de vente par statut : </h5>

                    <PieChart width={500} height={400} className="mt-0 pt-0">
                        <Pie
                            className="pt-0 mt-0"
                            dataKey="value"
                            startAngle={0}
                            endAngle={380}
                            data={statusVente}
                            outerRadius={100}
                            fill="#8884d8"
                            label

                        >
                        {
                            statusVente.map((entry, index) => <Cell key={index} fill={colors[index]}/>)
                        }
                        </Pie>
                        <Legend />
                        <Tooltip content={<CustomTooltip />} />

                    </PieChart>
                </Col> 


            </Row>
        </Spin>
    );
}



const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {

      return (
        <div className="custom-tooltip">
            <p className="label"><b>{`${payload[0].payload.payload.name}`} :</b> {`${payload[0].payload.payload.nombre}`}</p>
            <p className="label"><b>Montant :</b>  {`${(payload[0].payload.payload.value).toFixed(3)} TND`}</p>
        </div>
      );
    }
  
    return null;
  };
  