import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  message,
  Modal,
  Row,
  Spin,
  Switch,
  Tag,
  Tooltip,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { useEffect, useState } from "react";
import AppTable from "../../../uiHelpers/AppTabe";
import MarqueService from "../../../../services/marque.service";

const AddMarqueModalForm = ({ showModal, marqueAdded }) => {
  const [marqueForm] = Form.useForm();
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {}, [showModal]);

  const handleCancel = () => {
    closingModal();
    marqueAdded(false);
  };
  const closingModal = () => {
    setLoading(false);
    marqueForm.resetFields();
  };

  const addMarque = async (values) => {
    setLoading(true);
    var data = values;
    data.etat = true;
    const response = await MarqueService.addMarque(data);
    if (response.status === 200 || 204) {
      message.info("Marque ajouter avec success");
    } else message.error("Une erreur est survenu ! ");
    closingModal();
    marqueAdded(true);
  };

  return (
      <Modal
        visible={showModal}
        onOk={marqueForm.submit}
        onCancel={handleCancel}
        getContainer={false}
        okText="Enregistrer"
        cancelText="Annuler"
        centered
        width={600}
        title={"Ajouter une marque"}
      >
        <Form layout="vertical" form={marqueForm} onFinish={addMarque}>
          <Row>
            <Col span={24} className="px-2">
              <Form.Item
                label="Nom de la marque "
                name="nom"
                rules={[
                  {
                    required: true,
                    message:"Champ obligatoire avec maximum de caractère 100 !",
                    max: 99,
                  },
                ]}
              >
                <Input size="large" type="text" maxLength={100} />
              </Form.Item>
            </Col>

            <Col span={24} className="px-2">
              <Form.Item
                label="Description"
                name="description"
                rules={[
                  {
                    message:
                      "Champ obligatoire avec maximum de caractère 255 !",
                    max: 254,
                  },
                ]}
              >
                <TextArea rows={4} type="text" maxLength={255} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
  );
};
export default AddMarqueModalForm;
