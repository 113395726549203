import {
  DownloadOutlined, EditOutlined, FilePptOutlined, QuestionCircleOutlined,CopyOutlined
} from "@ant-design/icons";
import { Button, Card, Dropdown, Menu, message, Modal, Spin, Tooltip } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { environment } from "../../../env/config.env";
import PdfViewerModal from "../../../PdfViewerModal";
import Can from "../../../security/Can";
import ClientService from "../../../services/client.service";
import DevisService from "../../../services/devis.service";
import NotAuthorized from "../../uiHelpers/NotAuthorized";
import TableWithFiltres from "../../uiHelpers/TableFilters";
  const url = environment.serverUrl;
  
  const DashboardDevis = () => {
    const [isLoading, setLoading] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [updateId, setUpdateId] = useState();
    const [dataTable, setDataTable] = useState([]);
    const history = useHistory();
    const location = useLocation();
    const [showModal, setShowModal] = useState(false);
    const [reference,setReference] = useState();

    useEffect(() => {
        loadDevis();
      return () => {
        clearStates();
      };
    }, []);
  
    const clearStates = () => {
      setDataTable([]);
      setEditMode(false);
      setUpdateId(null);
    };
    const loadDevis = async () => {
      setLoading(true);
      await DevisService.getAllDevis(async (response)=> {
        if (response.status === 200 || 204) {
          const data = response.data;
          await ClientService.getClientsNames( (resp) => {
            if (response.status === 200 || 204) {
              initTableData(data , resp.data)
            }else message.error("Une erreur est survenu ! ");
          });
        }else message.error("Une erreur est survenu ! ");
        setLoading(false);
      });
    };
  

    const initTableData = (data ,clients) => {
      const showMode = localStorage.getItem("SHOW_MODE");
      const list = [];
      for (const element of data) {
        element.showDate = moment(new Date(element.date)).format("YYYY-MM-DD");
        const clientId = element.produits_devis[0]?.clientId ?? 0;
        element.clientId = clientId;
        element.client = getClientName(clientId,clients);
        if(showMode === "NO") {
          if(element.etat !== -1) list.push(element);
        } else list.push(element);
      }
      setDataTable(list);
    };
    const getClientName = (id ,list) => {
      for (const element of list) {
        if(element.id === id ) return element.nom + " " + element.prenom;
      }
    }
  
    const formatteEtat = (etat , bnStatus) => {
      switch( etat ){
        case 0 : return "En cours";
        case 1 : {
          if( bnStatus ) return "Validé";
          else return "Activé";
        }
        case -1 : return "Annulé";
        default : return ""
      }
    }
  
  
    const changeDevisStatus = async (id ,etat) => {
      const response = await DevisService.changeDevisStatus(id,etat);
      if (response.status === 200 || 204) {
        const msg = etat === 1 ? "Activé" : "desactivé" 
         message.info("Bon de commande " + msg );
      } else message.error("Une erreur est survenu ! ");
      loadDevis();
    }
  
    const navigate = (location) => {
      history.push(location);
    };
    
  
    const navigateToUpdate = (record) => {
      const id = record.id;
      history.push("/devis", {id});
    }
    const BonCommandes = [
      {
        title: "Référence",
        dataIndex: "reference",
        sorter: (a, b) => a.reference.localeCompare(b.reference),
      },
      {
        title: "Date",
        dataIndex: "showDate",
        sorter: (a, b) => a.showDate.localeCompare(b.showDate),
      },
      {
        title: "Client",
        dataIndex: "client",
        sorter: (a, b) => a.client.localeCompare(b.client),
      },
      
      {
        title: "Montant TTC",
        dataIndex: "montant_total",
        render: (text, record) => <b>{Number(record.montant_total).toFixed(3)} TND</b>,
        sorter: (a, b) => a.montant_total - b.montant_total,
      },{
        title: "Devise",
        dataIndex: "devise",
        sorter: (a, b) =>a.devise.localeCompare(b.devise),
      },{
        title: "Etat",
        dataIndex: "etat",
        render: (text, record) => <Dropdown.Button overlay={
          <Menu disabled={!Can("13_4") || record.bn_status}>
            <Menu.Item  disabled={record.etat === 1} className="px-5" key="1" onClick={()=> changeDevisStatus(record.id ,1)}>Activé</Menu.Item>
            <Menu.Item  disabled={record.etat === -1} className="px-5" key="2" onClick={()=> changeDevisStatus(record.id ,-1)}>Annulé</Menu.Item>
          </Menu>
        }>{formatteEtat(record.etat , record.bn_status)}</Dropdown.Button>
      },
      {
        title: "Action",
        key: "action",
        width : "15%",
        render: (text, record) => (
          <div>
          <Tooltip title="Dupliquer">
            <Button
              className="mx-1"
              type="dashed"
              shape="circle"
              disabled={!Can("10_5")}
              onClick={() => confirmDuplication(record)}
              icon={<CopyOutlined />}
            />
          </Tooltip>
            <Tooltip title="Visualiser">
              <Button
                className="mx-1"
                type="dashed"
                shape="circle"
                disabled={!Can("13_5")}
                onClick={() => showPDFModal(record)}
                icon={<FilePptOutlined />}
              />
            </Tooltip>
            <Tooltip title="Mettre à jour">
              <Button
                className="mx-1"
                type="dashed"
                disabled={record.etat === 1 || !Can("13_4")}
                shape="circle"
                onClick={() => navigateToUpdate(record)}
                icon={<EditOutlined />}
              />
            </Tooltip>
          </div>
        ),
      },
    ];
    const showPDFModal = (record) => {
      setShowModal(true);
      setReference(record.reference)
    } 
    const downloadFile = () => {
      window.open(url+"Files/vente/Devis/"+reference+".pdf", 'download');
    }
    const confirmDuplication = (record) => {
      Modal.confirm({
        icon: <QuestionCircleOutlined  />,
        content: "Êtes-vous sûr de dupliquer ce devis ?",
        okText: "Oui",
        onOk: () => duplicateData(record),
        cancelText: "Non",
      });
    };
    const duplicateData = async (record) => {
      const response = await DevisService.duplicate(record.id);
      if(response.data){
        const id  = response.data.id;
        message.success("Duplication avec success");
        history.push("/devis", {id});
      }else message.error("Une erreur est survenu ! ");
    }

    return (
      <>
      {Can("13_1") ? (
        <Spin spinning={isLoading} size="large">
          <Card
            title={"Devis"}
            extra={
              <Button
                className="mx-2"
                shape="round"
                type="primary"
                disabled={!Can("13_2")}

                onClick={() => navigate("/devis")}
              >
                Ajouter Un Devis
              </Button>
            }
          >
            <TableWithFiltres data={dataTable} columns={BonCommandes} />
          </Card>
          <Modal
          title={
            <>
              <div className="d-flex justify-content-between ">
                <div className="my-0">
                  Bon de commande : {reference}
                </div>
                <div className="px-5 ">
                  <Button  onClick={()=> downloadFile()} icon={<DownloadOutlined />} >
                    Telecharger
                  </Button>
                </div>
              </div>
            </>
          }
          centered
          visible={showModal}
          footer={null}
          width={1000}
          onCancel={()=> setShowModal(false)}
      >
        { showModal && 
          <div className="heigt-500px">
            <PdfViewerModal 
              diractory="Files/vente/Devis/" 
              reference={reference}  
            ></PdfViewerModal>
          </div>
        }
        

      </Modal>
        </Spin>
        ) : (
          <NotAuthorized></NotAuthorized>
        )}
      </>
    );
  };
  export default DashboardDevis;
  