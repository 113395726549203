
import authHeader from "./auth-header";

import axios from "axios";

import { environment } from "../env/config.env";

const API_URL = environment.api;



const getQuantite = (id) => {
  return axios.get(API_URL + "/stock/" + id , { headers: authHeader() });
};
const update = (id , data) => {
  return axios.put(API_URL + "/stock/"+id , data, { headers: authHeader() });
}
const getStores = (id , data) => {
  return axios.get(API_URL + "/stock/stores", { headers: authHeader() });
}
const addStore = ( data) => {
  return axios.post(API_URL + "/stock/store" , data, { headers: authHeader() });
}

const deleteStore = ( id ) => {
  return axios.delete(API_URL + "/stock/store/" + id, { headers: authHeader() });
}

const updateStore = ( id , data) => {
  return axios.put(API_URL + "/stock/store/" + id,  data , { headers: authHeader() });
}

const updateStoreProducts = ( id , data) => {
  return axios.post(API_URL + "/stock/product/" + id,  data , { headers: authHeader() });
}


const exportsMethodes = {
    update,
    getQuantite,
    getStores,
    addStore,
    deleteStore,
    updateStore,
    updateStoreProducts
};
export default exportsMethodes;