import {
  CheckOutlined, DeleteOutlined,
  EditOutlined, LockOutlined
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Row,
  Select,
  Spin,
  Tag,
  Tooltip
} from "antd";
import React, { useEffect, useState } from "react";
import Can from "../../../security/Can";
import UserService from "../../../services/user.service";
import AppTable from "../../uiHelpers/AppTabe";
import country from "../../../helpers/country";

const User = () => {
  const [userForm] = Form.useForm();
  const [passwordForm] = Form.useForm();
  const [userModal, setUserModal] = useState(false);
  const [passwordModal, setPasswordModal] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [updateId, setUpdateId] = useState();
  const [updateName, setUpdateName] = useState("");

  const [dataTable, setDataTable] = useState([]);
  const [switchValue, setSwitchValue] = useState("unchecked");
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    loadRoles();
    return () => {
      clearStates();
    };
  }, []);

  const clearStates = () => {
    setDataTable([]);
    setEditMode(false);
    setUpdateId(null);
  };
  const loadUsers = async (roles) => {
    setLoading(true);
    const response = await UserService.getUsers();
    if (response.status === 200 || 204) initTableData(response.data, roles);
    else message.error("Une erreur est survenu ! ");
    setLoading(false);
  };

  const loadRoles = async () => {
    setLoading(true);
    const response = await UserService.getRoles();
    if (response.status === 200 || 204) {
      setRoles(response.data);
      loadUsers(response.data);
    } else message.error("Une erreur est survenu ! ");
    setLoading(false);
  };
  const initTableData = (data, roles) => {
    for (const element of data)
      element.showRole = roles.filter(
        (role) => role.id === element.authRoleId
      )[0]?.nom;

    setDataTable(data);
  };

  const showUserModal = () => {
    setUserModal(true);
  };

  const handleOk = () => {
    setUserModal(false);
  };

  const updateMode = (record) => {
    setUserModal(true);
    const checkedSwitch = record.etat ? "checked" : "unchecked";
    setSwitchValue(checkedSwitch);
    userForm.setFieldsValue(record);
    setUpdateId(record.id);
    setEditMode(true);
  };
  const updatePassword = (record) => {
    setPasswordModal(true);
    setUpdateName(record.nom_prenom);
    setUpdateId(record.id);
    setEditMode(true);
  };

  const handleCancel = () => {
    setUserModal(false);
    clearData();
  };

  const handleCancelPasswordModal = () => {
    setPasswordModal(false);
    passwordForm.resetFields();
    setUpdateName(null)
    setUpdateId(null);
    setEditMode(false);
  };

  const addUser = async (values) => {
    setLoading(true);
    var data = values;
    console.log(data.etat);
    data.etat = data.etat ? data.etat : false;
    if( editMode ) delete data.etat;
    const response = editMode
      ? await UserService.updateUser(updateId, data)
      : await UserService.addUser(data);
    if (response.status === 200 || 204) {
      if (editMode) message.info("Mise à jour avec success");
      else message.info("Utilisateur ajouter avec success");
    } else message.error("Une erreur est survenu ! ");

    closingModal();
  };


  const updatePasswordFinish = async (values) => {
    setLoading(true);
    values.token = localStorage.getItem("user");
    const response = await UserService.updatePassword(updateId,values);
    if(response.data.STAT === "OK"){
      message.info("Mise à jour du mot de passe avec success");
    }else message.error("Une erreur est survenu !");
    setLoading(false);
    handleCancelPasswordModal()
  }

  const closingModal = () => {
    loadUsers(roles);
    setUserModal(false);
    setLoading(false);
    clearData();
  };

  const clearData = () => {
    userForm.resetFields();
    setEditMode(false);
    setUpdateId(null);
  };

  const UserColumns = [
    {
      title: "Nom",
      dataIndex: "nom_prenom",
      sorter: (a, b) => a.nom.localeCompare(b.nom),
    },
    {
      title: "E-mail",
      dataIndex: "email",
      sorter: (a, b) => a.email.localeCompare(b.email),
    },
    {
      title: "Téléphone ",
      dataIndex: "telephone",
      sorter: (a, b) => a.telephone - b.telephone,
    },
    {
      title: "Role",
      dataIndex: "showRole",
      sorter: (a, b) => a.showRole.localeCompare(b.showRole),
    }, {
      title: "Etat",
      key: "etat",
      width: "13%",
      render: (text, record) => (
        <div>
          { record.etat ? <Tag color="#87d068">Activé</Tag> : <Tag color="#f50">Désactivé</Tag> }

        </div>
      ),
    },
    {
      title: "Action",
      key: "action",
      width: "15%",
      render: (text, record) => (
        <div>
          <Tooltip title="Mettre à jour le mot de passe">
            <Button
              className="mx-1"
              type="dashed"
              shape="circle"
              disabled={!Can("5_3") || record.etat === 0}
              onClick={() => updatePassword(record) }
              icon={<LockOutlined />}
            />
          </Tooltip>
          <Tooltip title="Mettre à jour">
            <Button
              className="mx-1"
              type="dashed"
              shape="circle"
              disabled={!Can("5_3") || record.id === 1 || record.etat === 0}
              onClick={() => updateMode(record)}
              icon={<EditOutlined />}
            />
          </Tooltip>
          <Tooltip title={record.etat === 1 ? "Désactivé" : "Activé"}>
            <Button
              className="mx-1"
              type="dashed"
              shape="circle"
              disabled={!Can("5_7") || record.id === 1 }
              onClick={() => changeStatus(record)}
              icon={record.etat === 1 ? <DeleteOutlined />: <CheckOutlined />}
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  const changeStatus = async (record) => {
    setLoading(true);
    const status = record.etat === 1 ? 0 : 1
    const response = await UserService.updateStatus(record.id , status);
    if (response.data) {
      message.info(status === 1 ? "Utilisateur Activé" : "Utilisateur Désactivé ");
      loadUsers(roles);
    } else message.error("Une erreur est survenu ! ");
    setLoading(false);
  };

  return (
    <Spin spinning={isLoading} size="large">
      <Card
        title={"Utilisateurs"}
        extra={
          <Button
            className="mx-2"
            shape="round"
            type="primary"
            disabled={!Can("5_2")}
            onClick={() => showUserModal()}
          >
            Ajouter un Utilisateur
          </Button>
        }
      >
        <AppTable data={dataTable} columns={UserColumns} />
        <Form layout="vertical" form={userForm} onFinish={addUser}>
          <Modal
            visible={userModal}
            onOk={userForm.submit}
            onCancel={handleCancel}
            getContainer={false}
            okText="Enregistrer"
            cancelText="Annuler"
            centered
            width={900}
            title={
              editMode ? "Modifier l'utilisateur" : "Ajouter un utilisateur"
            }
          >
            <Row>
              <Col span={24} className="px-2">
                <Form.Item
                  label="Nom et prenom"
                  name="nom_prenom"
                  rules={[
                    {
                      required: true,
                      message:
                        "Champ obligatoire avec maximum de caractère 100 !",
                      max: 99,
                    },
                  ]}
                >
                  <Input size="large" type="text" maxLength={100} />
                </Form.Item>
              </Col>

              <Col span={12} className="px-2">
                <Form.Item
                  label="E-mail"
                  name="email"
                  rules={[
                    {
                      type: "email",
                      message: "Format invalid!",
                    },
                    {
                      required: true,
                      message: "champ obligatoire !",
                    },
                  ]}
                >
                  <Input size="large" type="text" maxLength={100} />
                </Form.Item>
              </Col>
              <Col span={12} className="px-2">
              {!editMode && 
                <Form.Item
                  label="Mot de passe"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message:
                        "Champ obligatoire avec maximum de caractère 100 !",
                      max: 99,
                    },
                  ]}
                >
                  <Input.Password size="large" type="text" maxLength={100} />
                </Form.Item>
              }
              </Col>
              <Col span={5} className="px-2">
                <Form.Item
                  label="Code Tél"
                  name="code_phone"
                  rules={[
                    {
                      required: true,
                      message:
                        "Champ obligatoire avec maximum de caractère 100 !",
                    },
                  ]}
                >
                  <Select size="large">
                    {country &&
                      country.map((c) => (
                        <Select.Option
                          key={c.id}
                          value={c.telCode}
                        >
                          {c.telCode}  {c.name}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={7} className="px-2">
                <Form.Item
                  label="Numéro Téléphone "
                  name="telephone"
                  rules={[
                    {
                      required: true,
                      message:
                        "Champ obligatoire avec maximum de caractère 100 !",
                    },
                  ]}
                >
                  <InputNumber
                    size="large"
                    style={{ width: "100%" }}
                    min={0}
                    max={99999999999999}
                  />
                </Form.Item>
              </Col>
              <Col span={12} className="px-2">
                <Form.Item
                  label="Adresse"
                  name="adresse"
                  rules={[
                    {
                      required: true,
                      message:
                        "Champ obligatoire avec maximum de caractère 100 !",
                      max: 99,
                    },
                  ]}
                >
                  <Input size="large" type="text" maxLength={100} />
                </Form.Item>
              </Col>
              <Col span={24} className="px-2">
                <Form.Item
                  label="Role"
                  name="authRoleId"
                  rules={[
                    {
                      required: true,
                      message: "Champ obligatoire !",
                    },
                  ]}
                >
                  <Select size="large">
                    {roles &&
                      roles.map((role) => (
                        <Select.Option
                          disabled={!role.etat}
                          key={role.id}
                          value={role.id}
                        >
                          {role.nom}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Modal>
        </Form>
      </Card>

      <Modal
        visible={passwordModal}
        onOk={passwordForm.submit}
        onCancel={handleCancelPasswordModal}
        getContainer={false}
        okText="Enregistrer"
        cancelText="Annuler"
        centered
        width={600}
        title={"Modifier le mot de passe de l'utilisateur : " + updateName}
      >
        <Form layout="vertical" form={passwordForm} onFinish={updatePasswordFinish}>
          <Row>
            <Col span={24} className="px-2">
              <Form.Item
                label="Votre mot de passe"
                name="userPassword"
                rules={[
                  { required: true,
                    message: "Champ obligatoire avec maximum de caractère 100 !",
                    max: 99,
                  },
                ]}
              >
                <Input.Password size="large" type="text" />
              </Form.Item>
            </Col>

            <Col span={24} className="px-2">
              <Form.Item
                label={ "Le nouveau mot de passe de l'utlisateur : " + updateName}
                name="newPassword"
                rules={[{
                  required: true,
                  message:"Champ obligatoire avec maximum de caractère 100 !",
                  max: 99,
                },]}
              >
                <Input.Password size="large" type="text" />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </Spin>
  );
};
export default User;
