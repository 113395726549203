import React from 'react';

import { Layout } from 'antd';
const { Footer } = Layout;

function AppFooter() {
  return (
    <Footer style={{ textAlign: 'center' }}>LOOKUP ©2021 BY DND SERV </Footer>
  );
}
export default AppFooter;
