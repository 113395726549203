import authHeader from "./auth-header";

import axios from "axios";

import { environment } from "../env/config.env";

const API_URL = environment.api;

const addInventaire = async ( inventaire ) => {
  return await axios.post(API_URL + "/inventaire/" , inventaire ,{ headers: authHeader() });
};

const getAllInventaire = async ( callback ) => {
  callback( await axios.get(API_URL + "/inventaire/" , { headers: authHeader() }))
};


const getInventaire = async ( id, callback ) => {
  callback( await axios.get(API_URL + "/inventaire/" + id , { headers: authHeader() }))
};

const getInventairesNames = async (callback) => {
  callback( await axios.get(API_URL + "/inventaire/names" , { headers: authHeader() }))
}

const updateInventaire = (id , inventaire ) => {
  return axios.put(API_URL + "/inventaire/" + id , inventaire, { headers: authHeader() });
};

const deleteInventaire = (id) => {
  return axios.delete(API_URL + "/inventaire/" + id , { headers: authHeader() });
};
const changeInventaireStatus = (id,etat) => {
  return axios.post(API_URL + "/inventaire/change/" + id  ,{etat}, { headers: authHeader() });
};

const getLast = async (callback) => {
  callback( await axios.get(API_URL + "/inventaire/last", { headers: authHeader() }))
}
const exportsMethodes = {
  addInventaire,
  getAllInventaire,
  getInventaire,
  updateInventaire,
  deleteInventaire,
  changeInventaireStatus,
  getInventairesNames,
  getLast
};
export default exportsMethodes;