import {
  DeleteOutlined,
  EditOutlined,
  CheckOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  message,
  Modal,
  Row,
  Spin,
  Tag,
  Tooltip,
  Tree,
} from "antd";
import React, { useEffect, useState } from "react";
import Can from "../../../security/Can";
import UserService from "../../../services/user.service";
import AppTable from "../../uiHelpers/AppTabe";

const Role = () => {
  const [roleForm] = Form.useForm();
  const [userModal, setUserModal] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [updateId, setUpdateId] = useState();
  const [dataTable, setDataTable] = useState([]);
  const [treeData, setTreeData] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [checkedKeys, setCheckedKeys] = useState([]);

  useEffect(() => {
    loadPermissions();
    loadModulePermissions();
    loadRoles();
    return () => {
      clearStates();
    };
  }, []);

  const loadModulePermissions = async () => {
    const response = await UserService.loadModulesPermissions();
    setPermissions(response.data);
  };
  const loadPermissions = async () => {
    const response = await UserService.loadPermissions();
    formatteData(response.data);
  };

  const formatteData = (data) => {
    const table = [];
    for (const element of data) {
      const obj = {
        title: element.nom,
        key: element.id,
        children: getChildren(element.id, element.auth_module_permissions),
      };
      table.push(obj);
    }
    setTreeData(table);
  };

  const getChildren = (moduleId, modulePermissions) => {
    const children = [];
    for (const element of modulePermissions) {
      const obj = {
        title: element.auth_permission.nom,
        key: moduleId + "_" + element.auth_permission.id,
      };
      children.push(obj);
    }
    return children;
  };

    const updateMode = async (record) => {
        const response = await UserService.getRole(record.id);
        formatteDataUpdate(response.data);
        setUserModal(true);
        roleForm.setFieldsValue(record);
        setUpdateId(record.id);
        setEditMode(true);
    };

  const formatteDataUpdate = (data) => {
    const table = [];
    for (const element of data.auth_role_module_permissions) {
      if (element.etat === 1) {
        const permission =
          element.auth_module_permission.authModuleId +
          "_" +
          element.auth_module_permission.authPermissionId;
        table.push(permission);
      }
    }
    setCheckedKeys(table);
  };

  const clearStates = () => {
    setDataTable([]);
    setEditMode(false);
    setUpdateId(null);
  };
  const loadRoles = async () => {
    setLoading(true);
    const response = await UserService.getRoles();
    if (response.status === 200 || 204) initTableData(response.data);
    else message.error("Une erreur est survenu ! ");
    setLoading(false);
  };
  const initTableData = (data) => {
    setDataTable(data);
  };

  const showUserModal = () => {
    setUserModal(true);
  };

  const handleOk = () => {
    setUserModal(false);
  };

  const handleCancel = () => {
    setCheckedKeys([]);
    setUserModal(false);
    clearData();
  };

  const addUser = async (values) => {
    setLoading(true);
    const auth_role_module_permissions = [];
    for (const permission of permissions) {
      const frontPermissionFormat =
        permission.authModuleId + "_" + permission.authPermissionId;
        const etat = checkedKeys.includes(frontPermissionFormat);
        const perm = {
            authModulePermissionId: permission.id,
            etat,
        };
        if( editMode ) perm.authRoleId = updateId;
        
        auth_role_module_permissions.push(perm);
    }
    const role = {
      ...values,
      auth_role_module_permissions,
    };
    const response = editMode
      ? await UserService.updateRole(updateId, role)
      : await UserService.addRole(role);
    if (response.status === 200 || 204) {
      if (editMode) message.info("Mise à jour avec success");
      else message.info("Rôle ajouter avec success");
    } else message.error("Une erreur est survenu ! ");

    closingModal();
  };

  const closingModal = () => {
    loadRoles();
    setUserModal(false);
    setLoading(false);
    clearData();
  };

  const clearData = () => {
    roleForm.resetFields();
    setEditMode(false);
    setUpdateId(null);
  };

  const RoleCols = [
    {
      title: "Rôle",
      dataIndex: "nom",
      sorter: (a, b) => a.nom.localeCompare(b.nom),
    },
    
    {
      title: "Etat",
      dataIndex: "etat",
      width: "10%",

      render: (text, record) => (
        <div>
          { record.etat ? <Tag color="#87d068">Activé</Tag> : <Tag color="#f50">Désactivé</Tag> }
        </div>
      ),
      sorter: (a, b) => a.nom.localeCompare(b.nom),
    },
    {
      width: "10%",
      title: "Action",
      key: "action",
      render: (text, record) => (
        <div>
          <Tooltip title="Mettre à jour">
            <Button
              className="mx-1"
              type="dashed"
              disabled={!Can("6_3") || record.id === 1 }
              shape="circle"
              onClick={() => updateMode(record)}
              icon={<EditOutlined />}
            />
          </Tooltip>
          <Tooltip title={record.etat === 1 ? "Désactivé" : "Activé"}>
            <Button
              className="mx-1"
              type="dashed"
              shape="circle"
              disabled={!Can("5_7") || record.id === 1 }
              onClick={() => changeStatus(record)}
              icon={record.etat === 1 ? <DeleteOutlined />: <CheckOutlined />}
            />
          </Tooltip>
        </div>
      ),
    },
  ];
  const changeStatus = async (record) => {
    setLoading(true);
    const status = record.etat === 1 ? 0 : 1
    const response = await UserService.updateRoleStatus(record.id , status);
    if (response.data) {
      message.info(status === 1 ? "Rôle Activé" : "Rôle Désactivé ");
      loadRoles();
    } else message.error("Une erreur est survenu ! ");
    setLoading(false);
  };

  const onSelect = (selectedKeysValue, info) => {
    setSelectedKeys(selectedKeysValue);
  };
  const onCheck = (checkedKeysValue) => {
    setCheckedKeys(checkedKeysValue);
  };

  return (
    <Spin spinning={isLoading} size="large">
      <Card
        title={"Rôle"}
        extra={
          <Button
            className="mx-2"
            shape="round"
            type="primary"
            disabled={!Can("6_2")}
            onClick={() => showUserModal()}
          >
            Ajouter un Rôle
          </Button>
        }
      >
        <AppTable data={dataTable} columns={RoleCols} />
        <Form layout="vertical" form={roleForm} onFinish={addUser}>
          <Modal
            visible={userModal}
            onOk={roleForm.submit}
            onCancel={handleCancel}
            getContainer={false}
            okText="Enregistrer"
            cancelText="Annuler"
            centered
            width={900}
            title={editMode ? "Modifier le Rôle" : "Ajouter un Rôle"}
          >
            <Row>
              <Col span={24} className="px-2">
                <Form.Item
                  label="Nom du Rôle"
                  name="nom"
                  rules={[
                    {
                      required: true,
                      message:
                        "Champ obligatoire avec maximum de caractère 100 !",
                      max: 99,
                    },
                  ]}
                >
                  <Input size="large" type="text" maxLength={100} />
                </Form.Item>
              </Col>

              <Tree
                checkable
                onCheck={onCheck}
                treeData={treeData}
                onSelect={onSelect}
                checkedKeys={checkedKeys}
                selectedKeys={selectedKeys}
                height={"450px"}
              />
            </Row>
          </Modal>
        </Form>
      </Card>
    </Spin>
  );
};
export default Role;
