import React, { useEffect } from "react";

function FicheClient({ client }) {
  useEffect(() => {
  }, []);

  return (
    <>
      {client && (
        <>
          {client.type === 1 && (
            <>
              <span className="ligne-height"> <strong> Client :</strong> </span> <span className="description-text"> {client.raison_sociale} </span>
              <br />
              <span className="ligne-height"> <strong> Matricule Fiscal : </strong> </span> <span className="description-text"> {client.matricule_fiscal} </span>
              <br />
            </>
          )}

          <span className="ligne-height"> <strong> {client.type ? "Responsable" : "Client"} :  </strong>  </span> <span className="description-text"> {client.nom} {client.prenom} </span>
          <br />
          <span className="ligne-height"> <strong> Téléphone  : </strong> </span> <span className="description-text"> {client.telephone_pro} </span>
          <br />
          <span className="ligne-height"> <strong> Adresse :  </strong> </span> <span className="description-text">   {client.adresse} </span>
          <br />
          <span className="ligne-height"> <strong> Adresse :  </strong> </span> <span className="description-text">   {client.email} </span>
          <br />
          {client.site && (
            <>
              <span className="ligne-height"> <strong> Site web : </strong> </span> <span className="description-text"> {client.site} </span>
            </>
          )}
        </>
      )}
    </>
  );
}
export default FicheClient;
