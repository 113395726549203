import authHeader from "./auth-header";

import axios from "axios";

import { environment } from "../env/config.env";

const API_URL = environment.api;

const addTva = ( tva ) => {
  return axios.post(API_URL + "/tva/" , tva ,{ headers: authHeader() });
};

const getTvas = () => {
  return axios.get(API_URL + "/tva/" , { headers: authHeader() });
};
const getActiveTva = () => {
  return axios.get(API_URL + "/tva/active" , { headers: authHeader() });
};

const updateTva = (id , tva ) => {
  return axios.put(API_URL + "/tva/" + id , tva, { headers: authHeader() });
};

const deleteTva = (id) => {
  return axios.delete(API_URL + "/tva/" + id , { headers: authHeader() });
};

const exportsMethodes = {
  addTva,
  getTvas,
  updateTva,
  deleteTva,
  getActiveTva
};
export default exportsMethodes;