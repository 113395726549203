import {
    DeleteOutlined,
    EditOutlined,
    ExclamationCircleOutlined
  } from "@ant-design/icons";
  import {
    Button,
    Card,
    Col,
    DatePicker,
    Form,
    Input,
    InputNumber,
    message,
    Modal,
    Row,
    Spin,
    Switch,
    Tag,
    Tooltip
  } from "antd";
  import TextArea from "antd/lib/input/TextArea";
  import React, { useEffect, useState } from "react";
  import Can from "../../../../security/Can";
  import FormuleGratuiteService from "../../../../services/formuleGratuite.service";
  import TableWithFiltres from "../../../uiHelpers/TableFilters";
  
  const FormuleGratuite = () => {
    const [formuleGratuiteForm] = Form.useForm();
    const [categorieModal, setFormuleGratuiteModal] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [updateId, setUpdateId] = useState();
    const [dataTable, setDataTable] = useState([]);
    const [switchValue, setSwitchValue] = useState("unchecked");
  
  
    useEffect(() => {
      loadFormuleGratuites();
      return () => {
        clearStates();
      };
    }, []);
  
    const clearStates = () => {
      setDataTable([]);
      setEditMode(false);
      setUpdateId(null);
    };
    const loadFormuleGratuites = async () => {
      setLoading(true);
      const response = await FormuleGratuiteService.getFormuleGratuites();
      if (response.status === 200 || 204) initTableData(response.data);
      else message.error("Une erreur est survenu ! ");
      setLoading(false);
    };
    const initTableData = (data) => {
      setDataTable(data);
    };
  
    const showFormuleGratuiteModal = () => {
      setFormuleGratuiteModal(true);
    };
  
    const handleOk = () => {
      setFormuleGratuiteModal(false);
    };
  
    const updateMode = (record) => {
      setFormuleGratuiteModal(true);
      const checkedSwitch = record.etat ? "checked" : "unchecked";
      setSwitchValue(checkedSwitch);
      formuleGratuiteForm.setFieldsValue(record);
      setUpdateId(record.id);
      setEditMode(true);
    };
  
    const handleCancel = () => {
      setFormuleGratuiteModal(false);
      clearData();
    };
  
    const addFormuleGratuite = async (values) => {
      setLoading(true);
      var data = values
      data.etat = data.etat ? data.etat : false; 
      const response = editMode
        ? await FormuleGratuiteService.updateFormuleGratuite(updateId, data)
        : await FormuleGratuiteService.addFormuleGratuite(data);
      if (response.status === 200 || 204) {
        if (editMode) message.info("Mise à jour avec success");
        else message.info("Formule de gratuité ajouter avec success");
      } else message.error("Une erreur est survenu ! ");
  
      closingModal();
    };
  
    const closingModal = () => {
      loadFormuleGratuites();
      setFormuleGratuiteModal(false);
      setLoading(false);
      clearData();
    };
  
    const clearData = () => {
      formuleGratuiteForm.resetFields();
      setEditMode(false);
      setUpdateId(null);
    };
  
    const FormuleGratuiteColumns = [
      {
        title: "Nom de la formule ",
        dataIndex: "nom",
        sorter: (a, b) => a.nom.localeCompare(b.nom),
        width: "17%",
      },
      {
        title: "Description",
        dataIndex: "description",
        width: "55%",
        sorter: (a, b) => a.description.localeCompare(b.description),
      },
      {
        title: "Etat",
        dataIndex: "etat",
        sorter: (a, b) => a.valeur - b.valeur,
        render: (text, record) => (
          <Tag color={record.etat ? "cyan" : "red" }>{record.etat ? "Activé" : "Désactivé"}</Tag>
        ),
        width: "10%",
      },
      {
        title: "Action",
        key: "action",
        width: "10%",
        render: (text, record) => (
          <div>
            <Tooltip title="Mettre à jour">
              <Button
                className="mx-1"
                type="dashed"
                shape="circle"
                disabled={!Can("3_3")}
                onClick={() => updateMode(record)}
                icon={<EditOutlined />}
              />
            </Tooltip>
          </div>
        ),
      },
    ];
  
    const confirmDelete = (record) => {
      Modal.confirm({
        title: "Attention",
        icon: <ExclamationCircleOutlined />,
        content: "Êtes-vous sûr de vouloir supprimer cette formule de gratuité ?",
        okText: "Oui",
        onOk: () => deleteItem(record),
        cancelText: "Non",
      });
    };
    const deleteItem = async (item) => {
      const response = await FormuleGratuiteService.deleteFormuleGratuite(item.id);
      if (response.status === 204 || 200) {
        message.info("Suppression avec success");
        loadFormuleGratuites();
      } else message.error("Une erreur est survenu ! ");
    };
  
    return (
      <Spin spinning={isLoading} size="large">
        <Card
          
          title={"Formule De Gratuité"}
          extra={
              <Button
                className="mx-2"
                shape="round"
                type="primary"
                disabled={!Can("3_2")}
                onClick={() => showFormuleGratuiteModal()}
              >
                Ajouter une formule de gratuité
              </Button>
          }
        >
          <TableWithFiltres data={dataTable} columns={FormuleGratuiteColumns}></TableWithFiltres>
          <Form layout="vertical" form={formuleGratuiteForm} onFinish={addFormuleGratuite}>
            <Modal
              visible={categorieModal}
              onOk={formuleGratuiteForm.submit}
              onCancel={handleCancel}
              getContainer={false}
              okText="Enregistrer"
              cancelText="Annuler"
              centered
              width={750}
              title={
                <>
                  <div className="d-flex justify-content-between ">
                    <div className="my-0">
                      {editMode
                        ? "Modifier la formule de gratuité"
                        : "Ajouter une formule de gratuité"}
                    </div>
                    <div>
                      <Form.Item
                        className="my-0 mx-5"
                        name="etat"
                        valuePropName={switchValue}
                      >
                        <Switch
                          checkedChildren="Activer"
                          unCheckedChildren="Désactiver"
                          className="px-4"
                        />
                      </Form.Item>
                    </div>
                  </div>
                </>
              }
            >
              <Row>
                <Col span={24} className="px-2">
                  <Form.Item
                    label="Nom de la formule "
                    name="nom"
                    rules={[
                      {
                        required: true,
                        message:
                          "Champ obligatoire avec maximum de caractère 100 !",
                        max: 99,
                      },
                    ]}
                  >
                    <Input size="large" type="text" maxLength={100} />
                  </Form.Item>
                </Col>
                <Col span={12} className="px-2">
                    <Form.Item
                    label="Nombre de pièce acheté"
                    name="nombreAchete"
                    rules={[
                        {
                        required: true,
                        message:"Champ obligatoire !",
                        },
                    ]}
                    >
                    <InputNumber
                        size="large"
                        style={{ width: "100%" }}
                        min={0}
                        max={99999999999}
                    />
                    </Form.Item>
                </Col>
                <Col span={12} className="px-2">
                    <Form.Item
                        label="Nombre de picèce gratuit"
                        name="nombreGratuit"
                        rules={[
                            {
                            required: true,
                            message:"Champ obligatoire !",
                            },
                        ]}
                    >
                    <InputNumber
                        size="large"
                        style={{ width: "100%" }}
                        min={0}
                        max={99999999999}
                    />
                    </Form.Item>
                </Col>
 

                <Col span={24} className="px-2">
                  <Form.Item
                    label="Description"
                    name="description"
                    rules={[
                      {
                        message:
                          "Champ obligatoire avec maximum de caractère 255 !",
                        max: 254,
                      },
                    ]}
                  >
                    <TextArea rows={4} type="text" maxLength={255} />
                  </Form.Item>
                </Col>
              </Row>
            </Modal>
          </Form>
        </Card>
      </Spin>
    );
  };
  export default FormuleGratuite;
  