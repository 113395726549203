import { LogoutOutlined, SettingOutlined } from "@ant-design/icons";
import { Layout, Menu } from "antd";
import { React, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
const { Header } = Layout;

const { SubMenu } = Menu;
function TopNavigation() {
  const history = useHistory();
  const location = useLocation();
  const [myKey, setMyKey] = useState([]);

  const navigate = (location) => {
    history.push(location);
  };
  useEffect(() => {
    getRoute();
  }, [location]);

  const getRoute = () => {
    const selectedRoute = [];
    switch (location.pathname) {
      case "/parametres":
        selectedRoute.push("01");
        break;
      default:
    }
    setMyKey(selectedRoute);
  };

  const logout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("permissions");
    setTimeout(() => {
      window.location.reload(false);
    }, 500);
  }

  return (
    <>
      <Header mode="horizontal">
        <Menu mode="horizontal" theme="dark" selectedKeys={myKey}>
          <Menu.Item
            key="01"
            icon={<SettingOutlined />}
            onClick={() => navigate("/parametres")}
          >
            Paramètres
          </Menu.Item>
          <Menu.Item  key="02" icon={<LogoutOutlined />} onClick={() => logout()}>Déconnexion</Menu.Item>
        </Menu>
      </Header>
    </>
  );
}
export default TopNavigation;
