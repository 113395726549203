
import React from 'react';

import {  Layout, Result } from 'antd';

function NotAuthorized() {
  return (
    <div className='px-5 py-5'>
        <Result
            status="403"
            title="403"
            subTitle="Désolé, vous n'êtes pas autorisé à accéder à cette page."
        />

    </div>
  );
}
export default NotAuthorized;
