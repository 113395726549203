import authHeader from "./auth-header";

import axios from "axios";

import { environment } from "../env/config.env";

const API_URL = environment.api;

const addBonTransfert = async ( bon_transfert ) => {
  return await axios.post(API_URL + "/bon_transfert/" , bon_transfert ,{ headers: authHeader() });
};

const getAllBonTransfert = async ( callback ) => {
  callback( await axios.get(API_URL + "/bon_transfert/" , { headers: authHeader() }))
};


const getBonTransfert = async ( id, callback ) => {
  callback( await axios.get(API_URL + "/bon_transfert/" + id , { headers: authHeader() }))
};

const getActiveBonTransfert = async ( callback ) => {
  callback( await axios.get(API_URL + "/bon_transfert/active"  , { headers: authHeader() }))
};

const getBonTransfertsNames = async (callback) => {
  callback( await axios.get(API_URL + "/bon_transfert/names" , { headers: authHeader() }))
}

const getBonTransfertsRef = (bnTransferId) => {
  return axios.get(API_URL + "/bon_transfert/reference/"+ bnTransferId , { headers: authHeader() });
}

const updateBonTransfert = (id , bon_transfert ) => {
  return axios.put(API_URL + "/bon_transfert/" + id , bon_transfert, { headers: authHeader() });
};

const deleteBonTransfert = (id) => {
  return axios.delete(API_URL + "/bon_transfert/" + id , { headers: authHeader() });
};
const changeBonTransfertStatus = (id,etat) => {
  return axios.post(API_URL + "/bon_transfert/change/" + id  ,{etat}, { headers: authHeader() });
};

const getLast = async (callback) => {
  callback( await axios.get(API_URL + "/bon_transfert/last", { headers: authHeader() }))
}
const exportsMethodes = {
  addBonTransfert,
  getAllBonTransfert,
  getBonTransfert,
  updateBonTransfert,
  deleteBonTransfert,
  changeBonTransfertStatus,
  getBonTransfertsNames,
  getLast,
  getActiveBonTransfert,
  getBonTransfertsRef
};
export default exportsMethodes;