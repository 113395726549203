import authHeader from "./auth-header";

import axios from "axios";

import { environment } from "../env/config.env";

const API_URL = environment.api;

const addFormuleGratuite = ( formuleGratuite ) => {
  return axios.post(API_URL + "/formuleGratuite/" , formuleGratuite ,{ headers: authHeader() });
};

const getFormuleGratuites = () => {
  return axios.get(API_URL + "/formuleGratuite/" , { headers: authHeader() });
};
const getActiveFormuleGratuites = () => {
  return axios.get(API_URL + "/formuleGratuite/active" , { headers: authHeader() });
};

const updateFormuleGratuite = (id , formuleGratuite ) => {
  return axios.put(API_URL + "/formuleGratuite/" + id , formuleGratuite, { headers: authHeader() });
};

const deleteFormuleGratuite = (id) => {
  return axios.delete(API_URL + "/formuleGratuite/" + id , { headers: authHeader() });
};

const exportsMethodes = {
  addFormuleGratuite,
  getFormuleGratuites,
  updateFormuleGratuite,
  deleteFormuleGratuite,
  getActiveFormuleGratuites
};
export default exportsMethodes;