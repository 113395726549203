import {
    Card, Tabs
} from "antd";
import React from "react";
import Can from "../../../security/Can";
import NotAuthorized from "../../uiHelpers/NotAuthorized";
import Role from "./Role";
import User from "./User";
  
  const UsersRoles = () => {

  
    return (
        <Card  title={"Gestion des utilisateurs et des roles "}>
            <Tabs>
                <Tabs.TabPane tab="Utilisateurs" key="1">
                    {Can("5_1") ? (
                        <User></User>
                    ) : (
                        <NotAuthorized></NotAuthorized>
                    )}
                </Tabs.TabPane>
                <Tabs.TabPane tab="Rôles" key="2">
                    {Can("6_1") ? (
                        <Role></Role>
                    ) : (
                        <NotAuthorized></NotAuthorized>
                    )}
                </Tabs.TabPane>
            </Tabs>
        </Card>
    );
  };
  export default UsersRoles;
  