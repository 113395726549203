import authHeader from "./auth-header";

import axios from "axios";

import { environment } from "../env/config.env";

const API_URL = environment.api;

const addTaxe = ( taxe ) => {
  return axios.post(API_URL + "/taxe/" , taxe ,{ headers: authHeader() });
};

const getTaxes = () => {
  return axios.get(API_URL + "/taxe/" , { headers: authHeader() });
};

const checkTaxeFodec = () => {
  return axios.get(API_URL + "/taxe/checkTaxeFodec" , { headers: authHeader() });
};
const getActiveTaxes = () => {
  return axios.get(API_URL + "/taxe/active" , { headers: authHeader() });
};

const updateTaxe = (id , taxe ) => {
  return axios.put(API_URL + "/taxe/" + id , taxe, { headers: authHeader() });
};

const deleteTaxe = (id) => {
  return axios.delete(API_URL + "/taxe/" + id , { headers: authHeader() });
};

const exportsMethodes = {
  addTaxe,
  getTaxes,
  updateTaxe,
  deleteTaxe,
  getActiveTaxes,
  checkTaxeFodec
};
export default exportsMethodes;