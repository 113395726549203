import React from "react";
import { Card, Result } from "antd";
import LeadChart from "./dashboard/LeadChart";
import Can from "../../security/Can";
import { SmileOutlined } from "@ant-design/icons";

function Home() {
  return (
    <>
      <Card style={{ width: "100%" }}>
      <h4 className="text-center">
          Bienvenue à l'application de facturation
          <br />
      </h4>
      </Card>
      <Card style={{ width: "100%" }}>
      {Can("7_1") ? (
          <div className=" my-5">
            <LeadChart></LeadChart>
          </div>
      ) : (
        <Result
          icon={<SmileOutlined />}
        />  
      )}
      </Card>
    </>
  );
}
export default Home;


// Your render function
