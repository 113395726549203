import { CheckOutlined, DeleteOutlined, PlusCircleOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col, Divider, Form, Input, InputNumber, message, Modal, Row,
  Spin,
  Tooltip
} from "antd";
import Text from "antd/lib/typography/Text";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import BonCommandeService from "../../../services/bonCommande.service";
import BonReceptionService from "../../../services/bonReception.service";
import ClientService from "../../../services/client.service";
import EntrepriseService from "../../../services/entreprise.service";
import FournisseurService from "../../../services/fournisseur.service";
import ProduitService from "../../../services/produit.service";
import AppTable from "../../uiHelpers/AppTabe";
import FicheClient from "../../uiHelpers/Fiches/FicheClient";
import FicheDetails from "../../uiHelpers/Fiches/FicheDetails";
import FicheFooter from "../../uiHelpers/Fiches/FicheFooter";
import FicheFournisseur from "../../uiHelpers/Fiches/FicheFournisseur";
import FicheHeader from "../../uiHelpers/Fiches/FicheHeader";
import FicheTableGain from "../../uiHelpers/Fiches/FicheTableGain";
import FicheTableTotaux from "../../uiHelpers/Fiches/FicheTableTotaux";
import FicheTableTva from "../../uiHelpers/Fiches/FicheTableTva";
import TaxeService from "../../../services/taxe.service";
import NotAuthorized from "../../uiHelpers/NotAuthorized";
import Can from "../../../security/Can";
import writtenNumber from '/node_modules/written-number/lib/index';
import currencys from '../../../helpers/currency';

writtenNumber.defaults.lang = 'fr';

function BonReception() {
  const [productsForm] = Form.useForm();
  const [isLoading, setLoading] = useState(false);
  const [fournisseurs, setFournisseurs] = useState([]);
  const [fournisseur, setFournisseur] = useState(null);
  const [clients, setClients] = useState([]);
  const [client, setClient] = useState(null);
  const [entreprise, setEntreprise] = useState({});
  const [productsModal, setProductsModal] = useState(false);
  const [isDarkMode, setDarkMode] = useState(false);
  const [productList, setProductList] = useState([]);
  const [gains, setGain] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [appliedTaxes, setListAppliedTaxes] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [updateId, setUpdateId] = useState();
  const [bonCommandeId , setBonCommandeId] = useState();
  const [initalQuantite , setInitalQuantite] = useState();
  const [fodec , setFodec] = useState(false);
  const [writtenTotal,setWrittenTotal] = useState("");
  const [selectedCurrency,setSelectedCurrency] = useState("");


  const history = useHistory();

  const location = useLocation();

  const [prixTotal, setPrixTotal] = useState({
    montant_total: 0,
    total_ttc: 0,
    total_ht: 0,
    fodec: 0,
    tva: 0,
    totalTimbreFiscal: "0.000",
  });

  const isVente = false;

  useEffect(() => {
    loadClients();
    loadFournisseur();
    loadEntreprise();
    getThemeMode();
    setUpdate();
    return () => {
      clearStates();
    };
  }, []);
  


  const setUpdate = async () => {
    const id = location.state?.id;
    setLoading(true);
    if (!location.state?.editMode) {
      await BonCommandeService.getBonCommande(id, async (response) => {
        if (response.status === 200 || 204) {
          const activeFodec = response.data.fodec > 0 ? true : false; 
          setFodec(activeFodec)
          const validReception = await loadAllBonReception(id);
          const fournisseurId = response.data.produit_achats[0].fournisseurId;
          const productList = { ...response.data };
          productList.date = moment(productList.date);
          productList.fournisseur = fournisseurId;
          setProductsAndQuantites(productList, validReception);
          loadProduits(fournisseurId);
          calculeTotalTaxes(fournisseurId,activeFodec);
          fournisseurChanged();
          setEditMode(true);
          generateReference();
          setSelectedCurrency(response.data.devise);

        } else message.error("Une erreur est survenu ! ");
      });
    }else{
      await BonReceptionService.getBonReception(id, async (response) => {
        if (response.status === 200 || 204) { 
          const activeFodec = response.data.fodec > 0 ? true : false; 
          setFodec(activeFodec)
          const res = response.data;
          res.produit_achats = res.bon_reception_produits;
          const validReception = await loadAllBonReception(res.bonCommandeId);   
          const fournisseurId = res.produit_achats[0].fournisseurId;
          const productList = { ...res };
          productList.date = moment(productList.date);
          productList.fournisseur = fournisseurId;
          setProductsAndQuantitesUpdate(productList, validReception);
          loadProduits(fournisseurId);
          calculeTotalTaxes(fournisseurId,activeFodec);
          fournisseurChanged();
          setSelectedCurrency(response.data.devise);
          setUpdateId(id);
          setEditMode(true);
        } else message.error("Une erreur est survenu ! ");
      });
    }
    setLoading(false);
  };
  const setProductsAndQuantites = (productList, validReception) => {
    setBonCommandeId(productList.id);
    const ids = [];
    const lst = [];
    for (const element of productList.produit_achats) {
      ids.push(element.produitId);
      if( validReception?.length < 0 ){
        element.qtotal = element.quantite;
        element.qRest = element.quantite;
        element.qLivre = 0;
        element.qtotal = element.qLivre + element.qRest; 
      }else{
        element.qtotal = element.quantite;
        element.qRest = element.quantite;
        element.qLivre = 0; 
        for (const reception of validReception) {
          for (const prod of reception.bon_reception_produits) {
            if (element.produitId === prod.produitId) {
              element.qLivre += prod.quantite;
              element.qRest -= prod.quantite;
            } 
          }
        }
        delete element.id;
        element.quantite = element.qRest;
      }
      if( element.qRest !== 0)
        lst.push(element);
    }
    setSelectedIds(ids);
    productList.produit_achats = lst
    productsForm.setFieldsValue(productList);
  };
  const setProductsAndQuantitesUpdate = (productList, validReception) => {
    const ids = [];
    const lst = [];
    for (const element of productList.produit_achats) {
      ids.push(element.produitId);
      element.qLivre = 0;
      for (const reception of validReception) {
        for (const product of reception.bon_reception_produits) {
          if (element.produitId === product.produitId) {
            element.qtotal = product.qtotal;
            element.qLivre += product.quantite;
            element.qRest -= element.quantite;
          }
        }
      }
      setInitalQuantite(element.quantite);
      delete element.id;
      element.qRest =  element.qtotal - element.qLivre; 
      if(element.qRest > 0);
        lst.push(element);
    }
    setSelectedIds(ids);
    productList.produit_achats = lst
    productsForm.setFieldsValue(productList);
  }

  const generateReference = async () => {
    await BonReceptionService.getLast( ( response )=> {
      if (response.status === 200 || 204) {
        if(response.data.length === 0){
          var nbr  = "1";
          var reference = "BR-" + nbr.padStart(4, '0');
        }else{
          const lastRef = response.data.reference;
          const refNbr = (Number(lastRef.substring(3, 7)) + 1 ) + "";
          var reference = "BR-" + refNbr.padStart(4, '0');
        }
        productsForm.setFieldsValue({reference: reference ,date : moment(new Date()) } )
      }else message.error("Une erreur est survenu ! ");
      setLoading(false);
    });
  }

  const loadAllBonReception = async (id) => {
    setLoading(true);
    const response = await BonReceptionService.getActiveReception(id);
    if (response.status === 200 || 204) return response.data;
    else message.error("Une erreur est survenu ! ");
    setLoading(false);
  };

  const loadProduits = async (id) => {
    setLoading(true);
    const response = await ProduitService.getProduitsByFournisseur(id);
    if (response.status === 200 || 204) setProductList( () => { return formatteProductsToDataTable(response.data)});
    else message.error("Une erreur est survenu ! ");
    setLoading(false);
  };

  const getProducts = async (id) => {
    const response = await ProduitService.getProduitsByFournisseur(id);
    if (response.status === 200 || 204) return response.data;
    else message.error("Une erreur est survenu ! ");
  };

  const formatteProductsToDataTable = (produits) => {
    for (const produit of produits) {
      produit.showType = produit.type ? "Service" : "Produit";
      produit.ttcAchat = produit.produit_achats[0]?.prixTTC;
      produit.htAchat = produit.produit_achats[0]?.prixHT;
      produit.pqte = produit.stocks[0]?.quantite;
      produit.fournisseurId = produit.produit_achats[0]?.fournisseurId;
      produit.showFournisseur =
        produit.produit_achats[0]?.fournisseur?.raison_sociale ??
        "Produit proprietere";
      produit.ttcVente = produit.produit_ventes[0].prixTTC;
      produit.htVente = produit.produit_ventes[0].prixHT;
    }
    return produits;
  };

  const selectProduct = (produit) => {
    setSelectedIds((prev) => [...prev, produit.id]);
    formatteProduct(produit);
  };

  const removeElement = (produitId) => {
    const list = selectedIds;
    setSelectedIds(list.filter((idP) => idP !== produitId));

  };

  const isSelected = (id) => {
    return selectedIds.includes(id);
  };

  const formatteProduct = (produit) => {
    const qte = produit?.quantite ?? 1;

    if (module === "vente") {
      var prixUniteHt = produit?.produit_ventes[0]?.prixHT;
      var prixUniteTTC = produit?.produit_ventes[0]?.prixTTC;
      var tva = produit?.produit_ventes[0]?.tva;
      var ht =
        produit?.produit_ventes[0]?.total_prix_HT ??
        Number(prixUniteHt) * Number(qte);
      var ttc =
        produit?.produit_achats[0]?.total_prix_TTC ??
        Number(prixUniteTTC) * Number(qte);
    } else {
      var prixUniteHt = produit?.produit_achats[0]?.prixHT;
      var prixUniteTTC = produit?.produit_achats[0]?.prixTTC;
      var tva = produit?.produit_achats[0]?.tva;
      var ht =
        produit?.produit_achats[0]?.total_prix_HT ??
        Number(prixUniteHt) * Number(qte);
      var ttc =
        produit?.produit_achats[0]?.total_prix_TTC ??
        Number(prixUniteTTC) * Number(qte);
    }
    const remise = produit?.remise ?? 0;
    const product = {
      produitId: produit?.id,
      libelle: produit?.libelle,
      quantite: Number(qte),
      prixHT: Number(prixUniteHt),
      prixTTC: Number(prixUniteTTC),
      tva: Number(tva),
      remise: Number(remise),
      total_prix_HT: Number(ht).toFixed(3),
      total_prix_TTC: Number(ttc).toFixed(3),
    };
    const productList = productsForm.getFieldValue("produit_achats")
      ? productsForm.getFieldValue("produit_achats")
      : [];
    productList.push(product);
    productsForm.setFieldsValue({ produit_achats: productList });
    calculeTotalTaxes();
  };

  const getThemeMode = () => {
    const theme = localStorage.getItem("COLOR_THEME");
    const darkMode = theme === "DARK" ? true : false;
    setDarkMode(darkMode);
  };

  const loadFournisseur = async () => {
    setLoading(true);
    const response = await FournisseurService.getFournisseurs();
    if (response.status === 200 || 204) {
      setFournisseurs(response.data);
    } else message.error("Une erreur est survenu ! ");
    setLoading(false);
  };

  const loadClients = async () => {
    setLoading(true);
    const response = await ClientService.getClients();
    if (response.status === 200 || 204) {
      setClients(response.data);
    } else message.error("Une erreur est survenu ! ");
    setLoading(false);
  };

  const loadEntreprise = async () => {
    setLoading(true);
    const response = await EntrepriseService.getEntreprise();
    if (response.status === 200 || 204) {
      setEntreprise(response.data);
    } else message.error("Une erreur est survenu ! ");
    setLoading(false);
  };

  const clearStates = () => {
    setEditMode(false);
    setUpdateId(null);
  };

  const handleCancel = () => {
    setProductsModal(false);
  };
  const getFournisseurs = async () => {
    setLoading(true);
    const response = await FournisseurService.getFournisseurs();
    if (response.status === 200 || 204) {
      return response.data;
    } else message.error("Une erreur est survenu ! ");
    setLoading(false);
  };

  const fournisseurChanged = async () => {
    const fournisseurId = productsForm.getFieldValue("fournisseur");
    loadProduits(fournisseurId);
    const fournisseurList = fournisseurs.length
      ? fournisseurs
      : await getFournisseurs();
    
    const fournisseur = fournisseurList.find(
      (four) => four.id === fournisseurId
    );
    setFournisseur(fournisseur);
  };
  const priceChanged = (index) => {
    const products = productsForm.getFieldValue("produit_achats");
    const product = products[index];

    const taxe = product.tva;
    const quantite = product.quantite;
    const prixHT = Number(
      product.prixHT - product.prixHT * (product.remise / 100)
    ).toFixed(3);
    const prixTTC = (Number(prixHT) + Number((prixHT / 100) * taxe)).toFixed(3);
    const total_ht = Number(prixHT * quantite).toFixed(3);
    const total_ttc = Number(prixTTC * quantite).toFixed(3);

    products[index].total_prix_TTC = total_ttc;
    products[index].total_prix_HT = total_ht;
    productsForm.setFieldsValue({ products });
    calculeTotalTaxes();

  };


  const calculeTotalTaxes = async (fournisseurId ,activeFodec) => {
    const products = productsForm.getFieldValue("produit_achats");
    
    const list = [];
    const listGain = [];
    
    const isFodecActive = activeFodec ? activeFodec : fodec

    for (const product of products) {
      if (product.tva > 0.1) {
        const base = isFodecActive ? 
            Number(product.total_prix_HT) + Number(product.total_prix_HT * 1) / 100 :
            Number(product.total_prix_HT);
        const montantTaxes = isFodecActive ?
          ((Number(product.total_prix_HT) + Number(product.total_prix_HT * 0.01)) * (product.tva / 100)).toFixed(3) :
          (Number(product.total_prix_HT) * (product.tva / 100)).toFixed(3);
        const taxe = {
          tvaPorucentage: product.tva,
          montantTaxes: montantTaxes,
          base: base,
        };
        list.push(taxe);
      }
      const listProd = productList.length ? productList : await getProducts(fournisseurId); 
      const prod = listProd.find( prod => prod.id === product.produitId);
      const gain_ht = ((prod?.produit_ventes[0]?.prixHT * product.quantite ) - product.total_prix_HT).toFixed(3);
      const gain_ttc = (prod?.produit_ventes[0]?.prixTTC - product.prixTTC) * product.quantite;
      const gain = {gain_ht , gain_ttc};
      listGain.push(gain);
    }
    setGain(listGain)
    setListAppliedTaxes(list);
    calculateTotal(isFodecActive);
  };
  const calculateTotal = ( activeFodec ) => {
    const isFodecActive =  activeFodec ? activeFodec : fodec;
    const products = productsForm.getFieldValue("produit_achats");
    const list = [];
    let total_ht = 0;
    let tva = 0;
    let totalTimbreFiscal = 0;
    let total_ttc = 0;
    for (const product of products) {
      const montantTaxes = isFodecActive ? 
       (Number(product.total_prix_HT) + Number(product.total_prix_HT * 0.01)) * (product.tva / 100) :
       (Number(product.total_prix_HT) ) * (product.tva / 100) ;
      total_ht += Number(product.total_prix_HT);
      tva += montantTaxes;
    }
    let fodecMontant = isFodecActive ? Number(total_ht) / 100 : 0;
    total_ttc += Number(total_ht) + Number(tva) + Number(fodecMontant);
    let montant_total = (Number(total_ttc) + Number(totalTimbreFiscal)).toFixed(3);

    const total = { montant_total, total_ttc, total_ht, fodec: fodecMontant, tva, totalTimbreFiscal };
    setPrixTotal(total);
    
    const final = montant_total.toString().split(".");
    const currencyName = currencys.find(c => c.code === productsForm.getFieldValue("devise"));
    setWrittenTotal("Le compte est arrêté à la somme de " + writtenNumber(final[0]) + " " +currencyName.base + " , " +final[1] + " " + currencyName.sub);


  };

  const getProductId = (index, name) => {
    return productsForm.getFieldValue("produit_achats")[index]?.produitId;
  };

  const addReception = async () => {
    setLoading(true);
    const product = productsForm.getFieldsValue();
    for (const element of product.produit_achats) {
      element.fournisseurId = productsForm.getFieldValue("fournisseur");
    }
    const bonReception = { ...product, ...prixTotal };
    bonReception.etat = 0;
    bonReception.bon_reception_produits = bonReception.produit_achats;
    delete bonReception.produit_achats;
    bonReception.bonCommandeId = bonCommandeId;
    const updateId = location.state.id;
    
    const response = !location.state?.editMode ? 
      await BonReceptionService.addBonReception(bonReception): 
      await BonReceptionService.updateBonReception(updateId,bonReception);
    if (response.status === 200 || 204) {
      location.state?.editMode ? 
      message.info("Bon de commande Mise a jour Avec Success"):
      message.info("Bon de commande Ajouté Avec Success");
      history.push("/bon_Receptions");
    } else message.error("Une erreur est survenu ! ");
    setLoading(false);

  };

  const getMax = (index) => {
    const rest = productsForm.getFieldValue("produit_achats")[index]?.qRest;
    const max =  location.state?.editMode ? (Number(rest) + Number(initalQuantite)) : rest;     
    return max 
  };
  const getLibelle = (index) => {
    return productsForm.getFieldValue("produit_achats")[index]?.libelle;

  }

  const ProduitColumns = [
    {
      title: "Type",
      dataIndex: "showType",
      key: "1",
      sorter: (a, b) => a.type.localeCompare(b.type),
    },
    {
      title: "Libelle",
      dataIndex: "libelle",
      sorter: (a, b) => a.showNom.localeCompare(b.showNom),
    },
    {
      title: "Référence Int",
      dataIndex: "reference_interne",
      sorter: (a, b) => a.email.localeCompare(b.email),
    },
    {
      title: "Fournisseur",
      dataIndex: "showFournisseur",
      sorter: (a, b) => a.valeur - b.valeur,
    },{
      title: "Quantité",
      dataIndex: "pqte",
      sorter: (a, b) => a.pqte - b.pqte,
    },
    {
      title: "Achat TTC",
      dataIndex: "ttcAchat",
      sorter: (a, b) => a.valeur - b.valeur,
    },
    {
      title: "Vente TTC",
      dataIndex: "ttcVente",
      sorter: (a, b) => a.valeur - b.valeur,
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <div>
          <Tooltip title="Choisir">
            <Button
              disabled={isSelected(record.id)}
              className="mx-1"
              type="dashed"
              shape="circle"
              onClick={() => selectProduct(record)}
              icon={<CheckOutlined />}
            />
          </Tooltip>
        </div>
      ),
    },
  ];
  const changeCurrency = () => {
    const currency = productsForm.getFieldValue("devise");
    setSelectedCurrency(currency);
  }
  const disableForm = () => {
    return selectedIds.length === 0;
  }

  return (
    <>
      {Can("11_2") ? (
        <div className="px-5">
          <Spin spinning={isLoading} size="large">
            <Card className="w-100 card-shadow">
              <Form layout="vertical" initialValues={{ devise: "TND", }} form={productsForm} onFinish={addReception}>
                <Row>
                  <FicheHeader
                    moduleName={"Bon De Récéption"}
                    showClients={isVente}
                    entreprise={entreprise}
                    clients={clients}
                    fournisseurs={fournisseurs}
                    selectedClient={(client) => setClient(client)}
                    selectedFournisseur={() => fournisseurChanged()}
                  ></FicheHeader>
                </Row>
                <Row>
                  {isVente ? (
                    <Col span={8} className="px-2 pt-3">
                      <FicheClient client={client}></FicheClient>
                    </Col>
                  ) : (
                    <Col span={8} className="px-2 pt-3">
                      <FicheFournisseur fournisseur={fournisseur}></FicheFournisseur>
                    </Col>
                  )}
                  <Col span={8} offset={8} className="px-2 pt-3">
                    <FicheDetails
                      currencyChanged={ ()=>  changeCurrency()}
                    ></FicheDetails>
                  </Col>
                </Row>
                <Row>
                  <Col span="24" className="px-4">
                    <table
                      className={` mb-5 table ${isDarkMode ? "table-dark" : ""} `}
                    >
                      <thead className="pl-2">
                          <Row>
                            <Col span={1}>
                              <b>#</b>
                            </Col>
                            <Col span={6}>
                                <b>Libelle</b>  
                            </Col>
                            <Col span={1}>
                              <Tooltip title="Quantité Total">
                                <b>QT</b>
                              </Tooltip>
                            </Col>

                            <Col span={1}>
                              <Tooltip title="Quantité Livré">
                                <b>QL</b>
                              </Tooltip>
                            </Col>

                            <Col span={1}>
                              <Tooltip title="Quantité Reste à Livré">
                                <b>Q.R.L</b>
                              </Tooltip>
                            </Col>

                            <Col span={2}>
                              <b>Quantité</b>
                            </Col>

                            <Col span={2}>
                              <Tooltip title="Prix Unitaire HT">
                                <b>Prix HT</b>
                              </Tooltip>

                            </Col>

                            <Col span={2}>
                              <b>TVA</b>
                            </Col>

                            <Col span={2}>
                              <b>Remise</b>
                            </Col>

                            <Col span={2}>
                              <b>Total HT</b>
                            </Col>

                            <Col span={2}>
                              <b>Total TTC</b>
                            </Col>

                            <Col className="px-3" span={2}>
                                <Tooltip title="Ajouter un produit">
                                  <Button
                                    shape="circle"
                                    icon={  <PlusCircleOutlined />}
                                    onClick={() => setProductsModal(true)}
                                  />
                                </Tooltip>
                            </Col>

                          </Row>
                      </thead>
                      <tbody>
                        <Form.List name="produit_achats" label="">
                          {(fields, { add, remove }) => (
                            <>
                              <Row className="pb-1 mb-1 ">
                              {fields.map(({ key, name, ...restField }, index) => (
                                <>
                                  <Col className="mb-0 pb-0" span={1}>
                                      {index + 1}
                                  </Col>
                                  <Col className="mb-0 pb-0" span={6}>
                                    <Text className="mb-0 pb-0">{ getLibelle(index) }</Text>
                                      <Form.Item
                                        {...restField}
                                        name={[name, "libelle"]}
                                        style={{height:0}}
                                      >
                                        <Input
                                          type="hidden"
                                          bordered={false}
                                        />
                                      </Form.Item>
                                  </Col>
                                  <Col className="mb-0 pb-0" span={1}>
                                    <Form.Item
                                      {...restField}
                                      name={[name, "qtotal"]}
                                    >
                                      <InputNumber
                                        className={`${
                                          isDarkMode
                                            ? "total-input-dark"
                                            : "total-input-light"
                                        } `}
                                        disabled
                                        style={{ width: 50 }}
                                        bordered={false}
                                      />
                                    </Form.Item>
                                  </Col>
                                  <Col className="mb-0 pb-0" span={1}>
                                    <Form.Item
                                      {...restField}
                                      name={[name, "qLivre"]}
                                    >
                                      <InputNumber
                                        className={`${
                                          isDarkMode
                                            ? "total-input-dark"
                                            : "total-input-light"
                                        } `}
                                        disabled
                                        style={{ width: 50 }}
                                        bordered={false}
                                      />
                                    </Form.Item>
                                  </Col>
                                  <Col className="mb-0 pb-0" span={1}>
                                    <Form.Item
                                      {...restField}
                                      name={[name, "qRest"]}
                                    >
                                      <InputNumber
                                        className={`${
                                          isDarkMode
                                            ? "total-input-dark"
                                            : "total-input-light"
                                        } `}
                                        disabled
                                        bordered={false}
                                        style={{ width: 50 }}
                                      />
                                    </Form.Item>
                                  </Col>
                                  <Col className="mb-0 pb-0" span={2}>
                                    <Form.Item
                                      {...restField}
                                      name={[name, "quantite"]}
                                      rules={[
                                        {
                                          required: true,
                                          message:
                                            "Champ obligatoire avec maximum de caractère 100 !",
                                        },
                                      ]}
                                    >
                                      <InputNumber
                                        style={{ width: 60 }}
                                        onChange={() => priceChanged(index)}
                                        className="w-125px"
                                        min={0}
                                        max={getMax(index)}
                                      />
                                    </Form.Item>
                                  </Col>
                                  <Col className="mb-0 pb-0" span={2}>
                                    <Form.Item
                                      {...restField}
                                      name={[name, "prixHT"]}
                                      rules={[
                                        {
                                          required: true,
                                          message:
                                            "Champ obligatoire avec maximum de caractère 100 !",
                                        },
                                      ]}
                                    >
                                      <InputNumber
                                        className={`w-125px ${
                                          isDarkMode
                                            ? "total-input-dark"
                                            : "total-input-light"
                                        } `}
                                        disabled
                                        bordered={false}
                                        onChange={() => priceChanged(index)}
                                        style={{ width: 120 }}
                                        min={0}
                                        formatter={(value) => `${value}`}
                                        parser={(value) =>
                                          value.replace("", "")
                                        }
                                      />
                                    </Form.Item>
                                  </Col>
                                  <Col className="mb-0 pb-0" span={2}>
                                    <Form.Item
                                      {...restField}
                                      name={[name, "tva"]}
                                      rules={[
                                        {
                                          required: true,
                                          message:
                                            "Champ obligatoire avec maximum de caractère 100 !",
                                        },
                                      ]}
                                    >
                                      <InputNumber
                                        className={`w-125px ${
                                          isDarkMode
                                            ? "total-input-dark"
                                            : "total-input-light"
                                        } `}
                                        disabled
                                        bordered={false}
                                        onChange={() => priceChanged(index)}
                                        style={{ width: 60 }}
                                        min={0}
                                        max={100}
                                        formatter={(value) => `${value}%`}
                                        parser={(value) =>
                                          value.replace("%", "")
                                        }
                                      />
                                    </Form.Item>
                                  </Col>
                                  <Col className="mb-0 pb-0" span={2}>
                                    <Form.Item
                                      {...restField}
                                      name={[name, "remise"]}
                                      rules={[
                                        {
                                          required: true,
                                          message:
                                            "Champ obligatoire avec maximum de caractère 100 !",
                                        },
                                      ]}
                                    >
                                      <InputNumber
                                        className={`w-125px ${
                                          isDarkMode
                                            ? "total-input-dark"
                                            : "total-input-light"
                                        } `}
                                        disabled
                                        bordered={false}
                                        onChange={() => priceChanged(index)}
                                        min={0}
                                        max={100}
                                        style={{ width: 60 }}
                                        formatter={(value) => `${value}%`}
                                        parser={(value) =>
                                          value.replace("%", "")
                                        }
                                      />
                                    </Form.Item>
                                  </Col>
                                  <Col className="mb-0 pb-0" span={2}>
                                    <Form.Item
                                      {...restField}
                                      name={[name, "total_prix_HT"]}
                                      rules={[
                                        {
                                          required: true,
                                          message:
                                            "Champ obligatoire avec maximum de caractère 100 !",
                                        },
                                      ]}
                                    >
                                      <InputNumber
                                        className={` ${
                                          isDarkMode
                                            ? "total-input-dark"
                                            : "total-input-light"
                                        } `}
                                        disabled
                                        bordered={false}
                                        style={{ width: 120 }}
                                        min={0}
                                        formatter={(value) => `${value}`}
                                        parser={(value) =>
                                          value.replace("", "")
                                        }
                                      />
                                    </Form.Item>
                                  </Col>
                                  <Col className="mb-0 pb-0" span={2}>
                                    <Form.Item
                                      {...restField}
                                      name={[name, "total_prix_TTC"]}
                                    >
                                      <InputNumber
                                        className={`w-125px ${
                                          isDarkMode
                                            ? "total-input-dark"
                                            : "total-input-light"
                                        } `}
                                        disabled
                                        bordered={false}
                                        style={{ width: 120 }}
                                        min={0}
                                        formatter={(value) => `${value}`}
                                        parser={(value) =>
                                          value.replace("", "")
                                        }
                                      />
                                    </Form.Item>
                                  </Col>
                                  <Col className="mb-0 pb-0 px-3" span={2}>
                                    <Tooltip title="Supprimer le produit">
                                      <Button
                                        shape="circle"
                                        icon={ <DeleteOutlined />}
                                        onClick={() => {
                                          removeElement(getProductId(index));
                                          remove(name);
                                          calculeTotalTaxes()
                                        }}
                                      />
                                    </Tooltip>
                                  </Col>
                                </>
                              ))}
                              </Row>
                            </>
                          )}
                        </Form.List>
                      </tbody>
                    </table>
                  </Col>
                  <Col span={8} offset={16} className="px-4">
                    <table className={`table ${isDarkMode ? "table-dark" : ""} `}>
                      <thead>
                        <tr>
                          <th scope="col" className="pb-3">
                            Remise :{" "}
                          </th>
                          <th scope="col">
                            <Form.Item name="remise_global" className="my-0 py-1">
                              <InputNumber
                                style={{ width: "100%" }}
                                formatter={(value) => `${value}%`}
                                parser={(value) => value.replace("%", "")}
                                min={0}
                                disabled
                                max={100}
                              />
                            </Form.Item>
                          </th>
                        </tr>
                      </thead>
                    </table>
                  </Col>
                  <FicheTableGain selectedCurrency={selectedCurrency} gains={gains} isDarkMode={isDarkMode}></FicheTableGain>
                  <FicheTableTva
                    selectedCurrency={selectedCurrency}
                    isDarkMode={isDarkMode}
                    appliedTaxes={appliedTaxes}
                  ></FicheTableTva>
                  <FicheTableTotaux
                    fodec={fodec}
                    selectedCurrency={selectedCurrency}
                    isDarkMode={isDarkMode}
                    prixTotal={prixTotal}
                  ></FicheTableTotaux>
                </Row>
                <Divider className="text-capitalize">{writtenTotal}</Divider>


                <FicheFooter entreprise={entreprise}></FicheFooter>

                <Row className="d-flex flex-row-reverse pt-5 pb-2">
                  <Button
                    className="mx-2"
                    type="primary"
                    disabled={disableForm()}
                    onClick={productsForm.submit}
                  >
                    Enregistrer
                  </Button>
                </Row>
              </Form>
            </Card>

            <Modal
              visible={productsModal}
              getContainer={false}
              centered
              footer={null}
              onCancel={handleCancel}
              title={"Produits"}
              width={1000}
            >
              <Spin spinning={isLoading} size="large">
                <Card title={"Liste des produits"}>
                  <AppTable data={productList} columns={ProduitColumns} />
                </Card>
              </Spin>
            </Modal>
          </Spin>
        </div>
      ) : (
        <NotAuthorized></NotAuthorized>
      )}
    </>
  );
}

export default BonReception;
