import { Col } from "antd";
import React, { useEffect } from "react";

function FicheTableTotaux({ entreprise,isDarkMode ,prixTotal,selectedCurrency,fodec }) {
  useEffect(() => {
  }, [prixTotal,selectedCurrency]);
  return (
    <Col span="8" className="px-4">
      <table className={`table ${isDarkMode ? "table-dark" : "" } `}>
        <thead>
          <tr>
          <th scope="row">Totaux</th>

          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row">Total HT</th>
            <td>{Number(prixTotal?.total_ht).toFixed(3)} {selectedCurrency}</td>
          </tr>
          { fodec ? (
            <tr>
              <th scope="row">Fodec</th>
              <td>{Number(prixTotal?.fodec).toFixed(3)} {selectedCurrency}</td>
            </tr>
          ) : ( <></>)}
          <tr>
            <th scope="row">TVA</th>
            <td>{Number(prixTotal?.tva).toFixed(3)} {selectedCurrency}</td>
          </tr>
          <tr>
            <th scope="row">Total TTC</th>
            <td>{Number(prixTotal?.total_ttc).toFixed(3)} {selectedCurrency}</td>
          </tr>
          { prixTotal?.totalTimbreFiscal > 0 &&
            <tr>
              <th scope="row">Timbre fiscale</th>
              <td>{Number(prixTotal?.totalTimbreFiscal).toFixed(3)} {selectedCurrency}</td>
            </tr>
          }
          <tr>
            <th scope="row">Total</th>
            <th scope="row">{Number(prixTotal?.montant_total).toFixed(3)} {selectedCurrency}</th>
          </tr>
        </tbody>
      </table>
    </Col>
  );
}
export default FicheTableTotaux;
