import authHeader from "./auth-header";

import axios from "axios";

import { environment } from "../env/config.env";

const API_URL = environment.api;

const addBonReception = async ( bon_reception ) => {
  return await axios.post(API_URL + "/bon_reception/" , bon_reception ,{ headers: authHeader() });
};

const getBonReceptions = async ( callback ) => {
  callback( await axios.get(API_URL + "/bon_reception/" , { headers: authHeader() }))
};


const getBonReception = async ( id, callback ) => {
  callback( await axios.get(API_URL + "/bon_reception/" + id , { headers: authHeader() }))
};
const updateBonReception = (id , bon_reception ) => {
  return axios.put(API_URL + "/bon_reception/" + id , bon_reception, { headers: authHeader() });
};

const deleteBonReception = (id) => {
  return axios.delete(API_URL + "/bon_reception/" + id , { headers: authHeader() });
};
const changeEtat = (id ,etat) => {
  return axios.post(API_URL + "/bon_reception/state/" + id , {etat : etat} , { headers: authHeader() });
};

const getActiveReception = async (id) => {
  return await axios.get(API_URL + "/bon_reception/active/" + id ,{ headers: authHeader() });
}

const getLast = async (callback) => {
  callback( await axios.get(API_URL + "/bon_reception/last", { headers: authHeader() }))
}
const exportsMethodes = {
  addBonReception,
  getBonReceptions,
  getBonReception,
  updateBonReception,
  deleteBonReception,
  changeEtat,
  getActiveReception,
  getLast
};
export default exportsMethodes;