import authHeader from "./auth-header";

import axios from "axios";

import { environment } from "../env/config.env";

const API_URL = environment.api;

const addMarque = ( marque ) => {
  return axios.post(API_URL + "/marque/" , marque ,{ headers: authHeader() });
};

const getMarques = () => {
  return axios.get(API_URL + "/marque/" , { headers: authHeader() });
};
const getActiveMarques = () => {
  return axios.get(API_URL + "/marque/active" , { headers: authHeader() });
};

const updateMarque = (id , marque ) => {
  return axios.put(API_URL + "/marque/" + id , marque, { headers: authHeader() });
};

const deleteMarque = (id) => {
  return axios.delete(API_URL + "/marque/" + id , { headers: authHeader() });
};

const exportsMethodes = {
  addMarque,
  getMarques,
  updateMarque,
  deleteMarque,
  getActiveMarques
};
export default exportsMethodes;