import authHeader from "./auth-header";

import axios from "axios";

import { environment } from "../env/config.env";

const API_URL = environment.api;

const addPaiement = ( paiement ) => {
  return axios.post(API_URL + "/paiementVente/" , paiement ,{ headers: authHeader() });
};
const addAcompte = ( paiement ) => {
  return axios.post(API_URL + "/paiementVente/acompte" , paiement ,{ headers: authHeader() });
};

const getPaiements = () => {
  return axios.get(API_URL + "/paiementVente/" , { headers: authHeader() });
};
const getActivePaiements = (id) => {
  return axios.get(API_URL + "/paiementVente/active/" + id , { headers: authHeader() });
};



const updatePaiement = (id , paiement ) => {
  return axios.put(API_URL + "/paiementVente/" + id , paiement, { headers: authHeader() });
};

const deletePaiement = (id) => {
  return axios.delete(API_URL + "/paiementVente/" + id , { headers: authHeader() });
};
const deleteAcompte = (id) => {
  return axios.delete(API_URL + "/paiementVente/deleteAcompte/" + id , { headers: authHeader() });

  
}

const exportsMethodes = {
  addPaiement,
  getPaiements,
  updatePaiement,
  deletePaiement,
  getActivePaiements,
  addAcompte,
  deleteAcompte
};
export default exportsMethodes;