import { Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import React, { useEffect } from "react";
import { environment } from "./env/config.env";

const url = environment.serverUrl;
function PdfViewerModal({diractory,reference}) {
    useEffect(()=> {

    },[reference])

  return (

      <Viewer fileUrl={url+diractory+reference+".pdf"} />

  );
}
export default PdfViewerModal;