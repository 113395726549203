import authHeader from "./auth-header";

import axios from "axios";

import { environment } from "../env/config.env";

const API_URL = environment.api;

const addProjet = ( projet ) => {
  return axios.post(API_URL + "/projet/" , projet ,{ headers: authHeader() });
};

const getProjets = () => {
  return axios.get(API_URL + "/projet/" , { headers: authHeader() });
};
const getProjetsNames = () => {
  return axios.get(API_URL + "/projet/names" , { headers: authHeader() });
}

const updateProjetStatus = (projectId, etat) => {
  return axios.put(API_URL + "/projet/status/" + projectId , {etat} ,  { headers: authHeader() })
}

const updateProjet = (id , projet ) => {
  return axios.put(API_URL + "/projet/" + id , projet, { headers: authHeader() });
};

const deleteProjet = (id) => {
  return axios.delete(API_URL + "/projet/" + id , { headers: authHeader() });
};

const exportsMethodes = {
  addProjet,
  getProjets,
  updateProjet,
  deleteProjet,
  getProjetsNames,
  updateProjetStatus
};
export default exportsMethodes;