import { Table } from "antd";
import { Empty } from "antd";
import { useEffect } from "react";

const AppTable = ({ data, columns, expandableRow }) => {
  useEffect(()=>{

  },[columns])
  const locale = {
    emptyText: (
      <Empty description={"Le tableau est vide pour le moment "}></Empty>
    ),
  };
  return (
    <Table
      expandable={{ expandableRow }}
      showSorterTooltip={false}
      rowKey={(record) => record.id}
      locale={locale}
      columns={columns}
      dataSource={data}
      pagination={{ position: ["bottomCenter"], pageSize: 5 }}
    />
  );
};
export default AppTable;
