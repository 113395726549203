import authHeader from "./auth-header";

import axios from "axios";

import { environment } from "../env/config.env";

const API_URL = environment.api;

const addProduit = ( produit ) => {
  return axios.post(API_URL + "/produit/" , produit ,{ headers: authHeader() });
};

const getProduits = () => {
  return axios.get(API_URL + "/produit/" , { headers: authHeader() });
};

const getProduitsForDashboard = () => {
  return axios.get(API_URL + "/produit/all" , { headers: authHeader() });
};

const getProduitsMainStore = () => {
  return axios.get(API_URL + "/produit/main" , { headers: authHeader() });
};

const updateProduit = (id , produit ) => {
  return axios.put(API_URL + "/produit/" + id , produit, { headers: authHeader() });
};

const deleteProduit = (id) => {
  return axios.delete(API_URL + "/produit/" + id , { headers: authHeader() });
};

const getProduitsByFournisseur = (fournisseurId) => {
  return axios.get(API_URL + "/produit/fournisseur/" + fournisseurId , { headers: authHeader() });
}

const getProduitsByStore = (storeId) => {
  return axios.get(API_URL + "/produit/store/" + storeId , { headers: authHeader() });
}
const getProduit = async (id) => {
  return await axios.get(API_URL + "/produit/" + id , { headers: authHeader() });
}

const exportsMethodes = {
  addProduit,
  getProduits,
  getProduit,
  updateProduit,
  deleteProduit,
  getProduitsByFournisseur,
  getProduitsByStore,
  getProduitsMainStore,
  getProduitsForDashboard
};
export default exportsMethodes;